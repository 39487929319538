import { createAction } from 'redux-actions';
// types
import {
  IListOptions,
  IPieChartOverviewOptions,
  Models,
} from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IFormValues as IProductFormValues } from 'modules/product/containers/ProductManageForm';
import { IUsersOverviewInfo } from 'modules/user/reducers';

// get list
export const getListProducts = createAction<IListOptions>('GET_LIST_PRODUCTS');
export const getListProductsSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_LIST_PRODUCTS_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListProductsFail = createAction('GET_LIST_PRODUCTS_FAIL');
// create
export const createProduct = createAction<IProductFormValues>('CREATE_PRODUCT');
export const createProductSuccess = createAction('CREATE_PRODUCT_SUCCESS');
export const createProductFail = createAction('CREATE_PRODUCT_FAIL');
// update
export const editProduct = createAction<string>('EDIT_TAG');
export const editProductCancel = createAction<string>('EDIT_TAG_CANCEL');
export const updateProduct = createAction<Models.Product.IProduct>(
  'UPDATE_PRODUCT',
);
export const updateProductSuccess = createAction<INormalizedItemPayload>(
  'UPDATE_PRODUCT_SUCCESS',
);
export const updateProductFail = createAction('UPDATE_PRODUCT_FAIL');
// remove
export const removeProducts = createAction<string[]>('REMOVE_PRODUCTS');
export const removeProductsSuccess = createAction<string[]>(
  'REMOVE_PRODUCTS_SUCCESS',
);
export const removeProductsFail = createAction('REMOVE_PRODUCTS_FAIL');
// table
export const selectProductRow = createAction<string>('SELECT_PRODUCT_ROW');
export const setSelectedProductsRow = createAction<string[]>(
  'SET_SELECTED_PRODUCTS_ROW',
);
export const setProductsTableOptions = createAction<IListOptions>(
  'SET_PRODUCTS_TABLE_OPTIONS',
);
// search
export const getProductsBySearch = createAction<string>(
  'GET_PRODUCTS_BY_SEARCH',
);
export const getProductsBySearchSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_PRODUCTS_BY_SEARCH_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getProductsBySearchFail = createAction(
  'GET_PRODUCTS_BY_SEARCH_FAIL',
);
// overview
export const getProductsOverviewInfo = createAction<IPieChartOverviewOptions>(
  'GET_PRODUCTS_OVERVIEW_INFO',
);
export const getProductsOverviewInfoSuccess = createAction<IUsersOverviewInfo>(
  'GET_PRODUCTS_OVERVIEW_INFO_SUCCESS',
);
export const getProductsOverviewInfoFail = createAction(
  'GET_PRODUCTS_OVERVIEW_INFO_FAIL',
);
// get product
export const getProduct = createAction<string>('GET_PRODUCT');
export const getProductSuccess = createAction<INormalizedItemPayload>(
  'GET_PRODUCT_SUCCESS',
);
export const getProductFail = createAction('GET_PRODUCT_FAIL');
