import { schema } from 'normalizr';
// models
import { Product } from 'models/Product';
import { ProductComponent } from 'models/ProductComponent';
import { Category } from 'models/Category';

export const Recommendation = new schema.Entity(
  'recommendations',
  {
    product: Product,
    ingredient: Product,
    targetIngredient: Product,
    targetProduct: Product,
    component: ProductComponent,
    targetComponent: ProductComponent,
    category: Category,
    targetCategory: Category,
  },
  {
    idAttribute: '_id',
  },
);

export const ListRecommendations = [Recommendation];
