import {
  all,
  call,
  put,
  takeEvery,
  select,
  getContext,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import { ListRecommendations, Recommendation } from 'models/Recomendation';
// actions
import {
  getListRecommendations,
  getListRecommendationsSuccess,
  getListRecommendationsFail,
  createRecommendation,
  createRecommendationSuccess,
  createRecommendationFail,
  updateRecommendation,
  updateRecommendationSuccess,
  updateRecommendationFail,
  removeRecommendations,
  removeRecommendationsSuccess,
  removeRecommendationsFail,
  getRecommendationsOverviewInfoSuccess,
  getRecommendationsOverviewInfoFail,
  getRecommendationsOverviewInfo,
} from 'modules/recommendation/actions';
import { addAlert } from 'modules/core/actions';
// selectors
import { selectRecommendationsTableOptions } from 'modules/recommendation/selectors';
// types
import {
  Api,
  IListOptions,
  Models,
  IPieChartOverviewOptions,
} from '@healthplate/types';
import { IEntities } from 'services/types';

function* onGetListRecommendations({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recommendations.list, {
      ...payload,
      expand: [
        'product',
        'targetProduct',
        'category',
        'targetCategory',
        'component',
        'targetComponent',
        'ingredient',
        'targetIngredient',
      ],
    });

    const normalizedPayload = normalize(result.data, ListRecommendations);

    yield put(
      getListRecommendationsSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getListRecommendationsFail(e));
  }
}

function* onCreateRecommendation({
  payload,
}: Action<Api.Recommendation.ICreateAllTypesBody>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recommendations.create, payload);
    const options = yield select(selectRecommendationsTableOptions);

    const normalizedPayload = normalize(result, Recommendation);

    yield all([
      put(createRecommendationSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: 'Recommendation successfully created',
        }),
      ),
      put(getListRecommendations(options)),
    ]);
  } catch (e) {
    yield put(createRecommendationFail(e));
  }
}

function* onUpdateRecommendation({
  payload,
}: Action<Models.Recommendation.IAnyRecommendation>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recommendations.update, payload._id, payload);

    const normalizedPayload = normalize<
      Models.Recommendation.IAnyRecommendation,
      IEntities
    >(result, Recommendation);

    yield all([
      put(updateRecommendationSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: 'Recommendation successfully updated',
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateRecommendationFail(e));
  }
}

function* onRemoveRecommendations({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectRecommendationsTableOptions);

    yield all(
      payload.map((recommendationId) =>
        call(api.recommendations.delete, recommendationId),
      ),
    );

    yield all([
      put(removeRecommendationsSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Recommendations successfully removed',
        }),
      ),
      put(getListRecommendations(options)),
    ]);
  } catch (e) {
    yield put(removeRecommendationsFail(e));
  }
}

function* onGetRecommendationsOverviewInfo({
  payload,
}: Action<IPieChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recommendations.overview, payload);

    yield put(getRecommendationsOverviewInfoSuccess(result));
  } catch (e) {
    yield put(getRecommendationsOverviewInfoFail(e));
  }
}

export default function* initRecommendationSagas() {
  yield takeEvery(getListRecommendations, onGetListRecommendations);
  yield takeEvery(createRecommendation, onCreateRecommendation);
  yield takeEvery(updateRecommendation, onUpdateRecommendation);
  yield takeEvery(removeRecommendations, onRemoveRecommendations);
  yield takeEvery(
    getRecommendationsOverviewInfo,
    onGetRecommendationsOverviewInfo,
  );
}
