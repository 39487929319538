import { spawn } from 'redux-saga/effects';

import articleSaga from 'modules/article/saga';
import authSaga from 'modules/auth/saga';
import categorySaga from 'modules/category/saga';
import coreSaga from 'modules/core/saga';
import productSaga from 'modules/product/saga';
import productComponentSaga from 'modules/productComponent/saga';
import recipeSaga from 'modules/recipe/saga';
import recommendationSaga from 'modules/recommendation/saga';
import tagsSaga from 'modules/tag/saga';
import userSaga from 'modules/user/saga';

export default function* initRootSagas() {
  yield spawn(articleSaga);
  yield spawn(authSaga);
  yield spawn(categorySaga);
  yield spawn(coreSaga);
  yield spawn(productSaga);
  yield spawn(productComponentSaga);
  yield spawn(recipeSaga);
  yield spawn(recommendationSaga);
  yield spawn(tagsSaga);
  yield spawn(userSaga);
}
