import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
// icons
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// components
import UserMenu from 'modules/user/components/UserMenu';
// selectors
import { isAppMenuOpen } from 'modules/core/selectors';
// actions
import { toggleMenu } from 'modules/core/actions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
    background: 'linear-gradient(187.2deg, #EED373 0%, #B5943F 100%);',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector(isAppMenuOpen);
  const toggleDrawer = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, isOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(
            classes.menuButton,
            isOpen && classes.menuButtonHidden,
          )}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="close drawer"
          onClick={toggleDrawer}
          className={clsx(
            classes.menuButton,
            !isOpen && classes.menuButtonHidden,
          )}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap={true}
          className={classes.title}
        >
          HealthPlate
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
