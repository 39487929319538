import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
// material ui components
import { Drawer, Divider, List } from '@material-ui/core';
// components
import MenuLink from 'modules/core/components/MenuLink';
// selectors
import { isAppMenuOpen } from 'modules/core/selectors';
// utils
import { siteLinks, additionalLinks } from 'modules/core/utils/links';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minHeight: '100vh',
    paddingTop: 64,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  subList: {
    marginTop: 'auto',
  },
}));

const Menu = () => {
  const classes = useStyles();
  const isOpen = useSelector(isAppMenuOpen);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
      open={isOpen}
    >
      <List>
        {siteLinks.map((menuItem, index) => (
          <MenuLink
            key={index}
            icon={<menuItem.icon />}
            primary={menuItem.title}
            to={menuItem.link}
          />
        ))}
      </List>
      <Divider />
      <List className={classes.subList}>
        {additionalLinks.map((menuItem, index) => (
          <MenuLink
            key={index}
            icon={<menuItem.icon />}
            primary={menuItem.title}
            to={menuItem.link}
          />
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
