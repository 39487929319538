import { schema } from 'normalizr';

export const Tag = new schema.Entity(
  'tags',
  {},
  {
    idAttribute: '_id',
  },
);

export const ListTags = [Tag];
