import { createSelector } from 'reselect';
// models
import { ListRecommendations, Recommendation } from 'models/Recomendation';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import {
  IDefaultState as IRecommendationState,
  IRecommendationsOverviewInfo,
} from 'modules/recommendation/reducers';
import { IAllEntitiesState } from 'modules/core/selectors';
import { IListOptions, Models } from '@healthplate/types';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const RecommendationState = ({ recommendation }: State) => recommendation;

export const getListRecommendationsNormalized = createSelector<
  State,
  IRecommendationState,
  string[]
>(RecommendationState, prop('list.data'));

export const getListRecommendations = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Recommendation.IRecommendation[]
>(
  getListRecommendationsNormalized,
  getAllEntities,
  denormalizeProp(ListRecommendations),
);

export const selectPaginationNextUrl = createSelector<
  State,
  IRecommendationState,
  string | null
>(RecommendationState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  IRecommendationState,
  string | null
>(RecommendationState, prop('list.prevUrl'));

export const selectIsListRecommendationsLoading = createSelector<
  State,
  IRecommendationState,
  boolean
>(RecommendationState, prop('list.isLoading'));

export const getEditRecommendationId = createSelector<
  State,
  IRecommendationState,
  string | null
>(RecommendationState, prop('edit.recommendationId'));

export const getEditRecommendation = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Recommendation.IAnyRecommendation | null
>(getEditRecommendationId, getAllEntities, denormalizeProp(Recommendation));

export const getRecommendationsTotalCount = createSelector<
  State,
  IRecommendationState,
  number
>(RecommendationState, prop('list.totalCount'));

export const getSelectedRecommendationsRows = createSelector<
  State,
  IRecommendationState,
  string[]
>(RecommendationState, prop('table.selectedRows'));

export const selectRecommendationsTableOptions = createSelector<
  State,
  IRecommendationState,
  IListOptions
>(RecommendationState, prop('table.options'));

export const selectRecommendationsOverview = createSelector<
  State,
  IRecommendationState,
  IRecommendationsOverviewInfo
>(RecommendationState, prop('overview'));

export const selectIsRecommendationsOverviewLoading = createSelector<
  State,
  IRecommendationState,
  boolean
>(RecommendationState, prop('overview.isLoading'));
