import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { IconButton, Snackbar, makeStyles } from '@material-ui/core';
// actions
import { removeAlert } from 'modules/core/actions';
// selectors
import { getAlerts } from 'modules/core/selectors';
// icons
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';

const variantIcon: any = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#4caf50',
  },
  error: {
    backgroundColor: '#f44336',
  },
  info: {
    backgroundColor: '#2196f3',
  },
  warning: {
    backgroundColor: '#ff9800',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Alerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alerts = useSelector(getAlerts);

  const handleClose = (alertNumber: number) => (
    _: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(removeAlert(alertNumber));
  };

  return (
    <>
      {alerts.map((alert, index) => {
        const Icon = variantIcon[alert.type];
        return (
          <Snackbar
            key={index}
            style={{
              bottom: 60 * index,
              marginBottom: 20,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={true}
            autoHideDuration={5000}
            onClose={handleClose(index)}
            action={
              <IconButton color="inherit" onClick={handleClose(index)}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            }
            message={
              <span id="snackbar" className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                {alert.message}
              </span>
            }
            ContentProps={{
              'aria-describedby': 'snackbar',
              className: classes[alert.type],
            }}
          />
        );
      })}
    </>
  );
};

export default Alerts;
