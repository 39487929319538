import { createSelector } from 'reselect';
// types
import { State } from 'modules/reducers';
import { prop } from 'services/helpers';
import { IDefaultState } from 'modules/core/reducers';
import { IAlert } from 'modules/core/types';
// entities types
import { IEntitiesState as ITagsEntitiesState } from 'modules/tag/reducers';
import { IEntitiesState as ICategoriesEntitiesState } from 'modules/category/reducers';
import { IEntitiesState as IRecommendationsEntitiesState } from 'modules/recommendation/reducers';
import { IEntitiesState as IProductsEntitiesState } from 'modules/product/reducers';
import { IEntitiesState as IProductComponentsEntitiesState } from 'modules/productComponent/reducers';
import { IEntitiesState as IRecipesEntitiesState } from 'modules/recipe/reducers';
import { IEntitiesState as IArticlesEntitiesState } from 'modules/article/reducers';
import { IEntitiesState as IUsersEntitiesState } from 'modules/user/reducers';

export const CoreState = ({ core }: State) => core;

export interface IAllEntitiesState {
  tags: ITagsEntitiesState;
  categories: ICategoriesEntitiesState;
  recommendations: IRecommendationsEntitiesState;
  products: IProductsEntitiesState;
  productComponents: IProductComponentsEntitiesState;
  recipes: IRecipesEntitiesState;
  articles: IArticlesEntitiesState;
  users: IUsersEntitiesState;
}

export const getAllEntities = createSelector<
  State,
  ITagsEntitiesState,
  ICategoriesEntitiesState,
  IRecommendationsEntitiesState,
  IProductsEntitiesState,
  IProductComponentsEntitiesState,
  IRecipesEntitiesState,
  IArticlesEntitiesState,
  IUsersEntitiesState,
  IAllEntitiesState
>(
  ({ tag }) => tag.entities,
  ({ category }) => category.entities,
  ({ recommendation }) => recommendation.entities,
  ({ product }) => product.entities,
  ({ productComponent }) => productComponent.entities,
  ({ recipe }) => recipe.entities,
  ({ article }) => article.entities,
  ({ user }) => user.entities,
  (
    tagsEntities,
    categoriesEntities,
    recommendationsEntities,
    productsEntities,
    productComponentsEntities,
    recipesEntities,
    articlesEntities,
    usersEntities,
  ) => ({
    tags: tagsEntities,
    categories: categoriesEntities,
    recommendations: recommendationsEntities,
    products: productsEntities,
    productComponents: productComponentsEntities,
    recipes: recipesEntities,
    articles: articlesEntities,
    users: usersEntities,
  }),
);

export const isAppMenuOpen = createSelector<State, IDefaultState, boolean>(
  CoreState,
  prop('appMenu.isOpen'),
);

export const getAlerts = createSelector<State, IDefaultState, IAlert[]>(
  CoreState,
  prop('alerts.items'),
);
