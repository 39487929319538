import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getListArticles,
  getListArticlesSuccess,
  getListArticlesFail,
  createArticle,
  createArticleSuccess,
  createArticleFail,
  editArticle,
  editArticleCancel,
  updateArticle,
  updateArticleSuccess,
  updateArticleFail,
  removeArticles,
  removeArticlesSuccess,
  removeArticlesFail,
  selectArticleRow,
  setSelectedArticlesRow,
  setArticleTableOptions,
} from 'modules/article/actions';
// types
import {
  IEntitiesMap,
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IListOptions, Models } from '@healthplate/types';

// entities
export type IEntitiesState = IEntitiesMap<Models.Article.IArticle>;

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListArticlesSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.articles,
    }),
    [`${updateArticleSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.articles[payload.result],
    }),
  },
  entitiesDefaultState,
);

// list
interface IArticlesListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const articlesListDefaultState: IArticlesListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const articlesListReducer = handleActions<IArticlesListState, any, IListMeta>(
  {
    [`${getListArticles}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListArticlesSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getListArticlesFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  articlesListDefaultState,
);

// create
interface ICreateArticleState {
  isLoading: boolean;
}

const createArticleDefaultState: ICreateArticleState = {
  isLoading: false,
};

const createArticleReducer = handleActions<ICreateArticleState>(
  {
    [`${createArticle}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createArticleSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createArticleFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createArticleDefaultState,
);

// edit
interface IEditArticleState {
  isLoading: boolean;
  articleId: string | null;
}

const editArticleDefaultState: IEditArticleState = {
  isLoading: false,
  articleId: null,
};

const editArticleReducer = handleActions<IEditArticleState, string>(
  {
    [`${editArticle}`]: (state, { payload }) => ({
      ...state,
      articleId: payload,
    }),
    [`${editArticleCancel}`]: (state) => ({
      ...state,
      articleId: null,
    }),
    [`${updateArticle}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateArticleSuccess}`]: (state) => ({
      ...state,
      articleId: null,
      isLoading: false,
    }),
    [`${updateArticleFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editArticleDefaultState,
);

// remove
interface IRemoveArticleState {
  isLoading: boolean;
}

const removeArticleDefaultState: IRemoveArticleState = {
  isLoading: false,
};

const removeArticleReducer = handleActions<IRemoveArticleState>(
  {
    [`${removeArticles}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeArticlesSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeArticlesFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeArticleDefaultState,
);

// table
interface IArticlesTableState {
  selectedRows: string[];
  options: IListOptions;
}

const articlesTableDefaultState: IArticlesTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const articlesTableReducer = handleActions<IArticlesTableState, any>(
  {
    [`${selectArticleRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedArticlesRow}`]: (state, { payload }: Action<string[]>) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setArticleTableOptions}`]: (
      state,
      { payload }: Action<IListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  articlesTableDefaultState,
);

export interface IDefaultState {
  readonly list: IArticlesListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateArticleState;
  readonly edit: IEditArticleState;
  readonly remove: IRemoveArticleState;
  readonly table: IArticlesTableState;
}

export default combineReducers({
  list: articlesListReducer,
  entities: entitiesReducer,
  create: createArticleReducer,
  edit: editArticleReducer,
  remove: removeArticleReducer,
  table: articlesTableReducer,
});
