import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
// actions
import { toggleMenu, addAlert, removeAlert } from 'modules/core/actions';
// types
import { IAlert } from 'modules/core/types';

// menu
const menuDefaultState = {
  isOpen: true,
};
type IMenuDefaultState = typeof menuDefaultState;
const appMenuReducer = handleActions<IMenuDefaultState>(
  {
    [`${toggleMenu}`]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
    }),
  },
  menuDefaultState,
);

// alerts
interface IAlertsState {
  items: IAlert[];
}

const alertsDefaultState: IAlertsState = {
  items: [],
};

const alertsReducer = handleActions<IAlertsState, IAlert | number>(
  {
    [`${addAlert}`]: (state, { payload }) => ({
      ...state,
      items: [...state.items, payload as IAlert],
    }),
    [`${removeAlert}`]: (state, { payload }) => ({
      ...state,
      items: state.items.filter((_, index) => index !== (payload as number)),
    }),
  },
  alertsDefaultState,
);

export interface IDefaultState {
  readonly alerts: IAlertsState;
  readonly appMenu: IMenuDefaultState;
}

export default combineReducers({
  alerts: alertsReducer,
  appMenu: appMenuReducer,
});
