import { createAction } from 'redux-actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IFormValues } from 'modules/category/containers/CategoryManageForm';

// get
export const getCategories = createAction<IListOptions>('GET_CATEGORIES');
export const getCategoriesSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_CATEGORIES_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getCategoriesFail = createAction('GET_CATEGORIES_FAIL');
// create
export const createCategory = createAction<IFormValues>('CREATE_CATEGORY');
export const createCategorySuccess = createAction<INormalizedListPayload>(
  'CREATE_CATEGORY_SUCCESS',
);
export const createCategoryFail = createAction<Models.Category.ICategory>(
  'CREATE_CATEGORY_FAIL',
);
// edit
export const editCategory = createAction<string>('EDIT_CATEGORY');
export const editCategoryCancel = createAction('EDIT_CATEGORY_CANCEL');
export const updateCategory = createAction<Models.Category.ICategory>(
  'UPDATE_CATEGORY',
);
export const updateCategorySuccess = createAction<INormalizedItemPayload>(
  'UPDATE_CATEGORY_SUCCESS',
);
export const updateCategoryFail = createAction('UPDATE_CATEGORY_FAIL');
// remove
export const removeCategories = createAction<string[]>('REMOVE_CATEGORIES');
export const removeCategoriesSuccess = createAction<string[]>(
  'REMOVE_CATEGORIES_SUCCESS',
);
export const removeCategoriesFail = createAction<string[]>(
  'REMOVE_CATEGORIES_FAIL',
);
// table
export const selectCategoryRow = createAction<string>('SELECT_CATEGORY_ROW');
export const setSelectedCategoriesRow = createAction<string[]>(
  'SET_SELECTED_TAGS_ROW',
);
export const setTableOptions = createAction<IListOptions>(
  'SET_CATEGORY_TABLE_OPTIONS',
);
// search
export const getCategoriesBySearch = createAction<string>(
  'GET_CATEGORIES_BY_SEARCH',
);
export const getCategoriesBySearchSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_CATEGORIES_BY_SEARCH_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getCategoriesBySearchFail = createAction(
  'GET_CATEGORIES_BY_SEARCH_FAIL',
);
