import {
  all,
  call,
  put,
  select,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import { Category, ListCategories } from 'models/Category';
// selectors
import { selectTableOptions } from 'modules/category/selectors';
// actions
import {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFail,
  createCategory,
  createCategorySuccess,
  createCategoryFail,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFail,
  removeCategories,
  removeCategoriesSuccess,
  removeCategoriesFail,
  getCategoriesBySearch,
  getCategoriesBySearchSuccess,
  getCategoriesBySearchFail,
} from 'modules/category/actions';
import { addAlert } from 'modules/core/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import { IFormValues } from 'modules/category/containers/CategoryManageForm';
import { IEntities } from 'services/types';

function* onGetCategoriesList({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.categories.list, {
      ...payload,
      expand: ['tags', 'parents'],
    });

    const normalizedPayload = normalize(result.data, ListCategories);

    yield put(
      getCategoriesSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getCategoriesFail(e));
  }
}

function* onCreateCategory({ payload }: Action<IFormValues>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.categories.create, payload);
    const options = yield select(selectTableOptions);

    const normalizedPayload = normalize<Models.Category.ICategory, IEntities>(
      result,
      Category,
    );

    yield all([
      put(createCategorySuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: 'Category successfully created',
        }),
      ),

      put(getCategories(options)),
    ]);
  } catch (e) {
    yield put(createCategoryFail(e));
  }
}

function* onUpdateCategory({ payload }: Action<Models.Category.ICategory>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.categories.update, payload._id, payload);

    const normalizedPayload = normalize<Models.Category.ICategory, IEntities>(
      result,
      Category,
    );

    yield all([
      put(updateCategorySuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Category "${payload.name}" successfully updated`,
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateCategoryFail(e));
  }
}

function* onRemoveCategories({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectTableOptions);

    yield all(
      payload.map((categoryId) => call(api.categories.delete, categoryId)),
    );
    yield all([
      put(removeCategoriesSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Categories successfully removed',
        }),
      ),
      put(getCategories(options)),
    ]);
  } catch (e) {
    yield put(removeCategoriesFail(e));
  }
}

function* onSearchCategories({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.categories.list, {
      q: payload,
      expand: ['parents'],
    });

    const normalizedPayload = normalize(result.data, ListCategories);

    yield put(
      getCategoriesBySearchSuccess(normalizedPayload, {
        hasMore: result.hasMore,
      }),
    );
  } catch (e) {
    yield getCategoriesBySearchFail(e);
  }
}

export default function* initCategorySagas() {
  yield takeEvery(getCategories, onGetCategoriesList);
  yield takeEvery(createCategory, onCreateCategory);
  yield takeEvery(updateCategory, onUpdateCategory);
  yield takeEvery(removeCategories, onRemoveCategories);
  yield takeEvery(getCategoriesBySearch, onSearchCategories);
}
