import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getListProductComponents,
  getListProductComponentsSuccess,
  getListProductComponentsFail,
  createProductComponent,
  createProductComponentSuccess,
  createProductComponentFail,
  editProductComponent,
  editProductComponentCancel,
  updateProductComponent,
  updateProductComponentSuccess,
  updateProductComponentFail,
  removeProductComponents,
  removeProductComponentsSuccess,
  removeProductComponentsFail,
  selectProductComponentRow,
  setSelectedProductComponentsRow,
  setProductComponentsTableOptions,
  getProductComponentsBySearch,
  getProductComponentsBySearchSuccess,
  getProductComponentsBySearchFail,
} from 'modules/productComponent/actions';
import {
  getListProductsSuccess,
  getProductSuccess,
} from 'modules/product/actions';
import { getListRecommendationsSuccess } from 'modules/recommendation/actions';
// types
import {
  IEntitiesMap,
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IListOptions, Models } from '@healthplate/types';

// entities
export type IEntitiesState = IEntitiesMap<
  Models.ProductComponent.IProductComponent
>;

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListProductComponentsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.productComponents,
    }),
    [`${updateProductComponentSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.productComponents[payload.result],
    }),
    [`${getProductComponentsBySearchSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.productComponents,
    }),
    [`${getListProductsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.productComponents,
    }),
    [`${getProductSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      ...payload.entities.productComponents,
    }),
    [`${getListRecommendationsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.productComponents,
    }),
  },
  entitiesDefaultState,
);

// list
interface IProductComponentsListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const productComponentsListDefaultState: IProductComponentsListState = {
  isLoading: false,
  totalCount: 0,
  prevUrl: null,
  nextUrl: null,
  data: [],
};

const productComponentsListReducer = handleActions<
  IProductComponentsListState,
  any,
  IListMeta
>(
  {
    [`${getListProductComponents}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListProductComponentsSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getListProductComponentsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  productComponentsListDefaultState,
);

// create
interface ICreateProductComponentState {
  isLoading: boolean;
}

const createProductComponentDefaultState: ICreateProductComponentState = {
  isLoading: false,
};

const createProductComponentReducer = handleActions<
  ICreateProductComponentState
>(
  {
    [`${createProductComponent}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createProductComponentSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createProductComponentFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createProductComponentDefaultState,
);

// edit
interface IEditProductComponentState {
  isLoading: boolean;
  productComponentId: string | null;
}

const editRecipeDefaultState: IEditProductComponentState = {
  isLoading: false,
  productComponentId: null,
};

const editProductComponentReducer = handleActions<
  IEditProductComponentState,
  string
>(
  {
    [`${editProductComponent}`]: (state, { payload }) => ({
      ...state,
      productComponentId: payload,
    }),
    [`${editProductComponentCancel}`]: (state) => ({
      ...state,
      productComponentId: null,
    }),
    [`${updateProductComponent}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateProductComponentSuccess}`]: (state) => ({
      ...state,
      productComponentId: null,
      isLoading: false,
    }),
    [`${updateProductComponentFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editRecipeDefaultState,
);

// remove
interface IRemoveProductComponentState {
  isLoading: boolean;
}

const removeProductComponentDefaultState: IRemoveProductComponentState = {
  isLoading: false,
};

const removeProductComponentReducer = handleActions<
  IRemoveProductComponentState
>(
  {
    [`${removeProductComponents}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeProductComponentsSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeProductComponentsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeProductComponentDefaultState,
);

// table
interface IProductComponentsTableState {
  selectedRows: string[];
  options: IListOptions;
}

const productsTableState: IProductComponentsTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const productComponentsTableReducer = handleActions<
  IProductComponentsTableState,
  any
>(
  {
    [`${selectProductComponentRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedProductComponentsRow}`]: (
      state,
      { payload }: Action<string[]>,
    ) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setProductComponentsTableOptions}`]: (
      state,
      { payload }: Action<IListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  productsTableState,
);

// search
interface IProductComponentsState {
  readonly isLoading: boolean;
  readonly data: string[];
}

const productComponentsSearchDefaultState: IProductComponentsState = {
  isLoading: false,
  data: [],
};

const productComponentsSearchReducer = handleActions<
  IProductComponentsState,
  INormalizedListPayload,
  IListMeta
>(
  {
    [`${getProductComponentsBySearch}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getProductComponentsBySearchSuccess}`]: (state, { payload, meta }) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      isLoading: false,
    }),
    [`${getProductComponentsBySearchFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  productComponentsSearchDefaultState,
);

export interface IDefaultState {
  readonly list: IProductComponentsListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateProductComponentState;
  readonly edit: IEditProductComponentState;
  readonly remove: IRemoveProductComponentState;
  readonly table: IProductComponentsTableState;
  readonly search: IProductComponentsState;
}

export default combineReducers({
  list: productComponentsListReducer,
  entities: entitiesReducer,
  create: createProductComponentReducer,
  edit: editProductComponentReducer,
  remove: removeProductComponentReducer,
  table: productComponentsTableReducer,
  search: productComponentsSearchReducer,
});
