import {
  call,
  put,
  select,
  takeEvery,
  getContext,
  all,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import {
  ListProductComponents,
  ProductComponent,
} from 'models/ProductComponent';
// selectors
import { selectProductComponentsTableOptions } from 'modules/productComponent/selectors';
// actions
import {
  getListProductComponents,
  getListProductComponentsSuccess,
  getListProductComponentsFail,
  createProductComponent,
  createProductComponentSuccess,
  createProductComponentFail,
  updateProductComponent,
  updateProductComponentSuccess,
  updateProductComponentFail,
  removeProductComponents,
  removeProductComponentsSuccess,
  removeProductComponentsFail,
  getProductComponentsBySearch,
  getProductComponentsBySearchSuccess,
  getProductComponentsBySearchFail,
} from 'modules/productComponent/actions';
import { addAlert } from 'modules/core/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import { IFormValues } from 'modules/productComponent/containers/ProductComponentManageForm';
import { IEntities } from 'services/types';

function* onGetListProductComponents({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.productComponents.list, payload);

    const normalizedPayload = normalize(result.data, ListProductComponents);

    yield put(
      getListProductComponentsSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getListProductComponentsFail(e));
  }
}

function* onCreateProductComponent({ payload }: Action<IFormValues>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.productComponents.create, payload);
    const options = yield select(selectProductComponentsTableOptions);

    const normalizedPayload = normalize(result, ProductComponent);

    yield all([
      put(createProductComponentSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: 'Product component successfully created',
        }),
      ),
      put(getListProductComponents(options)),
    ]);
  } catch (e) {
    yield put(createProductComponentFail(e));
  }
}

function* onUpdateProductComponent({
  payload,
}: Action<Models.ProductComponent.IProductComponent>) {
  try {
    const api = yield getContext('api');
    const result = yield call(
      api.productComponents.update,
      payload._id,
      payload,
    );

    const normalizedPayload = normalize<
      Models.ProductComponent.IProductComponent,
      IEntities
    >(result, ProductComponent);

    yield all([
      yield put(updateProductComponentSuccess(normalizedPayload)),
      yield put(
        addAlert({
          type: 'success',
          message: `Product component "${payload.name}" successfully updated`,
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateProductComponentFail(e));
  }
}

function* onRemoveProductComponents({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectProductComponentsTableOptions);

    yield all(
      payload.map((productComponentId) =>
        call(api.productComponents.delete, productComponentId),
      ),
    );
    yield all([
      put(removeProductComponentsSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Product components successfully removed',
        }),
      ),
      put(getListProductComponents(options)),
    ]);
  } catch (e) {
    yield put(removeProductComponentsFail(e));
  }
}

function* onSearchProductComponents({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.productComponents.list, {
      q: payload,
    });

    const normalizedPayload = normalize(result.data, ListProductComponents);

    yield put(
      getProductComponentsBySearchSuccess(normalizedPayload, {
        hasMore: false,
        totalCount: 0,
      }),
    );
  } catch (e) {
    yield put(getProductComponentsBySearchFail(e));
  }
}

export default function* initProductComponentSagas() {
  yield takeEvery(getListProductComponents, onGetListProductComponents);
  yield takeEvery(createProductComponent, onCreateProductComponent);
  yield takeEvery(updateProductComponent, onUpdateProductComponent);
  yield takeEvery(removeProductComponents, onRemoveProductComponents);
  yield takeEvery(getProductComponentsBySearch, onSearchProductComponents);
}
