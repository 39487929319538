import { createAction } from 'redux-actions';
// types
import {
  Api,
  Models,
  IPieChartOverviewOptions,
  ILineChartOverviewOptions,
} from '@healthplate/types';
import { INormalizedListPayload } from 'services/types';
import { IUsersListMeta, IUsersOverviewInfo } from 'modules/user/reducers';

// list
export const getListUsers = createAction<Api.User.IGetUsersListOptions>(
  'GET_USERS',
);
export const getListUsersSuccess = createAction<
  INormalizedListPayload,
  IUsersListMeta
>(
  'GET_USERS_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListUsersFail = createAction('GET_USERS_FAIL');
export const getCurrentUser = createAction<string>('GET_CURRENT_USER');
export const getCurrentUserSuccess = createAction<Models.User.IUser>(
  'GET_CURRENT_USER_SUCCESS',
);
export const getCurrentUserFail = createAction<any>('GET_CURRENT_USER_FAIL');
// table
export const setUsersTableOptions = createAction<Api.User.IGetUsersListOptions>(
  'SET_USERS_TABLE_OPTIONS',
);
// overview
export const getUsersOverviewInfo = createAction<IPieChartOverviewOptions>(
  'GET_USERS_OVERVIEW_INFO',
);
export const getUsersOverviewInfoSuccess = createAction<IUsersOverviewInfo>(
  'GET_USERS_OVERVIEW_INFO_SUCCESS',
);
export const getUsersOverviewInfoFail = createAction(
  'GET_USERS_OVERVIEW_INFO_FAIL',
);
// health index
export const getUserHealthIndex = createAction<
  string,
  ILineChartOverviewOptions
>(
  'GET_USER_HEALTH_INDEX',
  (payload) => payload,
  (_, options) => options,
);
export const getUserHealthIndexSuccess = createAction<
  { value: number; startTime: number }[]
>('GET_USER_HEALTH_INDEX_SUCCESS');
export const getUserHealthIndexFail = createAction(
  'GET_USER_HEALTH_INDEX_FAIL',
);
// mood index
export const getUserMoodIndex = createAction<string, ILineChartOverviewOptions>(
  'GET_USER_MOOD_INDEX',
  (payload) => payload,
  (_, options) => options,
);
export const getUserMoodIndexSuccess = createAction<
  {
    value: number;
    startTime: number;
  }[]
>('GET_USER_MOOD_INDEX_SUCCESS');
export const getUserMoodIndexFail = createAction('GET_USER_MOOD_INDEX_FAIL');
// comfort index
export const getUserComfortIndex = createAction<
  string,
  ILineChartOverviewOptions
>(
  'GET_USER_COMFORT_INDEX',
  (payload) => payload,
  (_, options) => options,
);
export const getUserComfortIndexSuccess = createAction<
  { value: number; startTime: number }[]
>('GET_USER_COMFORT_INDEX_SUCCESS');
export const getUserComfortIndexFail = createAction(
  'GET_USER_COMFORT_INDEX_FAIL',
);
