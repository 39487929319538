import React from 'react';
import { render } from 'react-dom';
import App from 'App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

if (
  process.env.NODE_ENV === 'production' &&
  window.location.protocol !== 'https:'
) {
  window.location.href =
    'https:' + window.location.href.substring(window.location.protocol.length);
} else {
  render(<App />, rootElement);
}

serviceWorker.register();
