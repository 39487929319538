import { createAction } from 'redux-actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IFormValues as IArticleFormValues } from 'modules/article/containers/ArticleManageForm';

// get
export const getListArticles = createAction<IListOptions>('GET_LIST_ARTICLES');
export const getListArticlesSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_LIST_ARTICLES_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListArticlesFail = createAction('GET_LIST_ARTICLES_FAIL');
// create
export const createArticle = createAction<IArticleFormValues>('CREATE_ARTICLE');
export const createArticleSuccess = createAction<INormalizedItemPayload>(
  'CREATE_ARTICLE_SUCCESS',
);
export const createArticleFail = createAction('CREATE_ARTICLE_FAIL');
// edit
export const editArticle = createAction<string>('EDIT_ARTICLE');
export const editArticleCancel = createAction('EDIT_ARTICLE_CANCEL');
export const updateArticle = createAction<Models.Article.IArticle>(
  'UPDATE_ARTICLE',
);
export const updateArticleSuccess = createAction('UPDATE_ARTICLE_SUCCESS');
export const updateArticleFail = createAction('UPDATE_ARTICLE_FAIL');
// remove
export const removeArticles = createAction<string[]>('REMOVE_ARTICLES');
export const removeArticlesSuccess = createAction('REMOVE_ARTICLES_SUCCESS');
export const removeArticlesFail = createAction('REMOVE_ARTICLES_FAIL');
// table
export const selectArticleRow = createAction<string>('SELECT_ARTICLE_ROW');
export const setSelectedArticlesRow = createAction<string[]>(
  'SET_SELECTED_ARTICLES_ROW',
);
export const setArticleTableOptions = createAction<IListOptions>(
  'SET_ARTICLE_TABLE_OPTIONS',
);
