import { schema } from 'normalizr';

export const ProductComponent = new schema.Entity(
  'productComponents',
  {},
  {
    idAttribute: '_id',
  },
);

export const ListProductComponents = [ProductComponent];
