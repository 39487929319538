import { createSelector } from 'reselect';
// models
import {
  ProductComponent,
  ListProductComponents,
} from 'models/ProductComponent';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import { IDefaultState as IProductComponentState } from 'modules/productComponent/reducers';
import { IListOptions, Models } from '@healthplate/types';
import { IAllEntitiesState } from 'modules/core/selectors';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const ProductComponentState = ({ productComponent }: State) => productComponent;

export const selectListProductComponentsNormalized = createSelector<
  State,
  IProductComponentState,
  string[]
>(ProductComponentState, prop('list.data'));

export const selectListProductComponents = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.ProductComponent.IProductComponent[]
>(
  selectListProductComponentsNormalized,
  getAllEntities,
  denormalizeProp(ListProductComponents),
);

export const selectPaginationNextUrl = createSelector<
  State,
  IProductComponentState,
  string | null
>(ProductComponentState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  IProductComponentState,
  string | null
>(ProductComponentState, prop('list.prevUrl'));

export const selectListProductComponentsTotalCount = createSelector<
  State,
  IProductComponentState,
  number
>(ProductComponentState, prop('list.totalCount'));

export const selectIsListProductComponentsLoading = createSelector<
  State,
  IProductComponentState,
  boolean
>(ProductComponentState, prop('list.isLoading'));

export const selectEditProductComponentId = createSelector<
  State,
  IProductComponentState,
  string | null
>(ProductComponentState, prop('edit.productComponentId'));

export const selectEditProductComponent = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.ProductComponent.IProductComponent
>(
  selectEditProductComponentId,
  getAllEntities,
  denormalizeProp(ProductComponent),
);

export const getSelectedProductComponentsRows = createSelector<
  State,
  IProductComponentState,
  string[]
>(ProductComponentState, prop('table.selectedRows'));

export const selectProductComponentsTableOptions = createSelector<
  State,
  IProductComponentState,
  IListOptions
>(ProductComponentState, prop('table.options'));

export const selectProductComponentsSearchListNormalized = createSelector<
  State,
  IProductComponentState,
  string[]
>(ProductComponentState, prop('search.data'));

export const selectProductComponentsSearchList = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.ProductComponent.IProductComponent[]
>(
  selectProductComponentsSearchListNormalized,
  getAllEntities,
  denormalizeProp(ListProductComponents),
);

export const selectProductComponentsByIdArray = (idArray: string[]) =>
  createSelector<
    State,
    string[],
    IAllEntitiesState,
    Models.ProductComponent.IProductComponent[]
  >(() => idArray, getAllEntities, denormalizeProp(ListProductComponents));
