import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFail,
  selectCategoryRow,
  setSelectedCategoriesRow,
  createCategory,
  createCategorySuccess,
  createCategoryFail,
  removeCategoriesSuccess,
  editCategory,
  editCategoryCancel,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFail,
  setTableOptions,
  getCategoriesBySearch,
  getCategoriesBySearchSuccess,
  getCategoriesBySearchFail,
} from 'modules/category/actions';
import {
  getListProductsSuccess,
  getProductSuccess,
} from 'modules/product/actions';
import { getListRecommendationsSuccess } from 'modules/recommendation/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';

// entities
export interface IEntitiesState {
  [id: string]: Models.Category.ICategory;
}

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getCategoriesSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.categories,
    }),
    [`${updateCategorySuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.categories[payload.result],
    }),
    [`${getCategoriesBySearchSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.categories,
    }),
    [`${getListProductsSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      ...payload.entities.categories,
    }),
    [`${getProductSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      ...payload.entities.categories,
    }),
    [`${getListRecommendationsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.categories,
    }),
  },
  entitiesDefaultState,
);

// list
interface ICategoriesListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const categoriesListDefaultState: ICategoriesListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const categoriesListReducer = handleActions<
  ICategoriesListState,
  any,
  IListMeta
>(
  {
    [`${getCategories}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getCategoriesSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getCategoriesFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeCategoriesSuccess}`]: (state, { payload }: Action<string[]>) => ({
      ...state,
      data: state.data.filter(
        (categoryId) =>
          !payload.find((removeCategoryId) => removeCategoryId === categoryId),
      ),
    }),
  },
  categoriesListDefaultState,
);

// create
interface ICreateCategoryState {
  isLoading: boolean;
}

const createCategoryDefaultState: ICreateCategoryState = {
  isLoading: false,
};

const createCategoryReducer = handleActions<ICreateCategoryState, any>(
  {
    [`${createCategory}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createCategorySuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createCategoryFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createCategoryDefaultState,
);

// edit
interface IEditCategoryState {
  isLoading: boolean;
  categoryId: string | null;
}

const editCategoryDefaultState: IEditCategoryState = {
  isLoading: false,
  categoryId: null,
};

const editCategoryReducer = handleActions<IEditCategoryState, string>(
  {
    [`${editCategory}`]: (state, { payload }) => ({
      ...state,
      categoryId: payload,
    }),
    [`${editCategoryCancel}`]: (state) => ({
      ...state,
      categoryId: null,
    }),
    [`${updateCategory}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateCategorySuccess}`]: (state) => ({
      ...state,
      categoryId: null,
      isLoading: false,
    }),
    [`${updateCategoryFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editCategoryDefaultState,
);

// table
interface ICategoriesTableState {
  selectedRows: string[];
  options: IListOptions;
}

const categoriesTableState: ICategoriesTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const categoriesTableReducer = handleActions<ICategoriesTableState, any>(
  {
    [`${selectCategoryRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedCategoriesRow}`]: (
      state,
      { payload }: Action<string[]>,
    ) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setTableOptions}`]: (state, { payload }: Action<IListOptions>) => ({
      ...state,
      options: payload,
    }),
  },
  categoriesTableState,
);

// search
interface ICategoriesSearchState {
  isLoading: boolean;
  data: string[];
}

const categoriesSearchState: ICategoriesSearchState = {
  isLoading: false,
  data: [],
};

const categoriesSearchReducer = handleActions<
  ICategoriesSearchState,
  INormalizedListPayload
>(
  {
    [`${getCategoriesBySearch}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getCategoriesBySearchSuccess}`]: (state, { payload }) => ({
      ...state,
      data: payload.result,
      isLoading: false,
    }),
    [`${getCategoriesBySearchFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  categoriesSearchState,
);

export interface IDefaultState {
  readonly list: ICategoriesListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateCategoryState;
  readonly edit: IEditCategoryState;
  readonly table: ICategoriesTableState;
  readonly search: ICategoriesSearchState;
}

export default combineReducers({
  list: categoriesListReducer,
  entities: entitiesReducer,
  create: createCategoryReducer,
  edit: editCategoryReducer,
  table: categoriesTableReducer,
  search: categoriesSearchReducer,
});
