import { put, getContext, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';
// actions
import {
  logout,
  setAccessToken,
  setAccessTokenSuccess,
} from 'modules/auth/actions';

function* onSetAccessToken({ payload }: Action<string>) {
  const api = yield getContext('api');
  api.setAccessToken(payload);
  yield put(setAccessTokenSuccess(payload));
}

function* onLogout() {
  const api = yield getContext('api');
  api.resetToken();
}

export default function* initAuthSagas() {
  yield takeEvery(setAccessToken, onSetAccessToken);
  yield takeEvery(logout, onLogout);
}
