import React from 'react';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        width: 'fit-content',
      },
      textPrimary: {
        color: '#fff',
        background: '#DDC064',
        borderRadius: '200px',
        paddingLeft: '25px',
        paddingRight: '25px',
        height: '48px',
        boxShadow:
          '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);',
        '&:hover': {
          backgroundColor: '#f1d16d',
        },
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          boxShadow: 'unset',
        },
      },
    },
    MuiTypography: {
      subtitle1: {
        fontSize: '18px',
        color: '#A68734',
      },
      subtitle2: {
        fontSize: '14px',
        color: '#77869E',
        fontWeight: 600,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        padding: '0',
        background: '#FBFBFB',
        color: '#CACDD5',
        '&:hover': {
          backgroundColor: 'rgba(221, 192, 100, .1)',
        },
        '&$checked': {
          color: '#DDC064',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px',
        borderBottom: '1px solid #F6F6F7',
      },
      head: {
        color: 'rgba(161, 163, 168, .5)',
      },
      body: {
        color: '#5C5C5C',
      },
      paddingCheckbox: {
        paddingLeft: '0',
      },
    },
    MuiTablePagination: {
      caption: {
        color: '#A1A3A8',
      },
      input: {
        opacity: '.7',
      },
      actions: {
        color: '#757575',
      },
    },
    MuiInputLabel: {
      root: {
        transform: 'scale(0.75)',
      },
    },
    MuiTabs: {
      indicator: {
        background: '#A68734',
      },
    },
    MuiTab: {
      textColorPrimary: {
        '&$selected': {
          color: '#A68734',
        },
      },
    },
  },
});

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
export default ThemeProvider;
