import { createSelector } from 'reselect';
// models
import { Recipe, ListRecipes } from 'models/Recipe';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// type
import { State } from 'modules/reducers';
import { IDefaultState as IRecipeState } from 'modules/recipe/reducers';
import { IAllEntitiesState } from 'modules/core/selectors';
import { IListOptions, Models } from '@healthplate/types';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const RecipeState = ({ recipe }: State) => recipe;

export const selectListRecipesNormalized = createSelector<
  State,
  IRecipeState,
  string[]
>(RecipeState, prop('list.data'));

export const selectListRecipes = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Recipe.IRecipe[]
>(selectListRecipesNormalized, getAllEntities, denormalizeProp(ListRecipes));

export const selectPaginationNextUrl = createSelector<
  State,
  IRecipeState,
  string | null
>(RecipeState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  IRecipeState,
  string | null
>(RecipeState, prop('list.prevUrl'));

export const selectEditRecipeId = createSelector<
  State,
  IRecipeState,
  string | null
>(RecipeState, prop('edit.recipeId'));

export const selectEditRecipe = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Recipe.IRecipe
>(selectEditRecipeId, getAllEntities, denormalizeProp(Recipe));

export const selectRecipesTotalCount = createSelector<
  State,
  IRecipeState,
  number
>(RecipeState, prop('list.totalCount'));

export const selectSelectedRecipesRows = createSelector<
  State,
  IRecipeState,
  string[]
>(RecipeState, prop('table.selectedRows'));

export const selectRecipesTableOptions = createSelector<
  State,
  IRecipeState,
  IListOptions
>(RecipeState, prop('table.options'));

export const selectIsListRecipesLoading = createSelector<
  State,
  IRecipeState,
  boolean
>(RecipeState, prop('list.isLoading'));
