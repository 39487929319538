import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getListRecommendations,
  getListRecommendationsSuccess,
  getListRecommendationsFail,
  createRecommendation,
  createRecommendationSuccess,
  createRecommendationFail,
  editRecommendation,
  editRecommendationCancel,
  updateRecommendation,
  updateRecommendationSuccess,
  updateRecommendationFail,
  removeRecommendations,
  removeRecommendationsSuccess,
  removeRecommendationsFail,
  selectRecommendationRow,
  setSelectedRecommendationsRow,
  setRecommendationsTableOptions,
  getRecommendationsOverviewInfo,
  getRecommendationsOverviewInfoSuccess,
  getRecommendationsOverviewInfoFail,
} from 'modules/recommendation/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';

// entities
export interface IEntitiesState {
  [id: string]: Models.Recommendation.IRecommendation;
}

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListRecommendationsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.recommendations,
    }),
    [`${updateRecommendationSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.recommendations[payload.result],
    }),
  },
  entitiesDefaultState,
);

// list
interface IRecommendationsListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const recommendationsListDefaultState: IRecommendationsListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const recommendationsListReducer = handleActions<
  IRecommendationsListState,
  any,
  IListMeta
>(
  {
    [`${getListRecommendations}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListRecommendationsSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getListRecommendationsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  recommendationsListDefaultState,
);

// create
interface ICreateRecommendationState {
  isLoading: boolean;
}

const createRecommendationDefaultState: ICreateRecommendationState = {
  isLoading: false,
};

const createRecommendationReducer = handleActions<ICreateRecommendationState>(
  {
    [`${createRecommendation}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createRecommendationSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createRecommendationFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createRecommendationDefaultState,
);

// edit
interface IEditRecommendationState {
  isLoading: boolean;
  recommendationId: string | null;
}

const editRecommendationDefaultState: IEditRecommendationState = {
  isLoading: false,
  recommendationId: null,
};

const editRecommendationReducer = handleActions<
  IEditRecommendationState,
  string
>(
  {
    [`${editRecommendation}`]: (state, { payload }) => ({
      ...state,
      recommendationId: payload,
    }),
    [`${editRecommendationCancel}`]: (state) => ({
      ...state,
      recommendationId: null,
    }),
    [`${updateRecommendation}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateRecommendationSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
      recommendationId: null,
    }),
    [`${updateRecommendationFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editRecommendationDefaultState,
);

// remove
interface IRemoveRecommendationState {
  isLoading: boolean;
}

const removeRecommendationDefaultState: IRemoveRecommendationState = {
  isLoading: false,
};

const removeRecommendationReducer = handleActions<IRemoveRecommendationState>(
  {
    [`${removeRecommendations}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeRecommendationsSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeRecommendationsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeRecommendationDefaultState,
);

// table
interface IRecommendationsTableState {
  selectedRows: string[];
  options: IListOptions;
}

const recommendationsTableDefaultState: IRecommendationsTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const recommendationsTableReducer = handleActions<
  IRecommendationsTableState,
  any
>(
  {
    [`${selectRecommendationRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedRecommendationsRow}`]: (
      state,
      { payload }: Action<string[]>,
    ) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setRecommendationsTableOptions}`]: (
      state,
      { payload }: Action<IListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  recommendationsTableDefaultState,
);

// overview
export interface IRecommendationsOverviewInfo {
  primaryValue: number;
  datasets: { label: string; value: number }[];
}

interface IRecommendationsOverviewState extends IRecommendationsOverviewInfo {
  isLoading: boolean;
}

const recommendationsOverviewDefaultState: IRecommendationsOverviewState = {
  isLoading: false,
  primaryValue: 0,
  datasets: [],
};

const recommendationsOverviewReducer = handleActions<
  IRecommendationsOverviewState,
  any
>(
  {
    [`${getRecommendationsOverviewInfo}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getRecommendationsOverviewInfoSuccess}`]: (
      state,
      { payload }: Action<IRecommendationsOverviewInfo>,
    ) => ({
      ...state,
      ...payload,
      isLoading: false,
    }),
    [`${getRecommendationsOverviewInfoFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  recommendationsOverviewDefaultState,
);

export interface IDefaultState {
  readonly list: IRecommendationsListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateRecommendationState;
  readonly edit: IEditRecommendationState;
  readonly remove: IRemoveRecommendationState;
  readonly table: IRecommendationsTableState;
  readonly overview: IRecommendationsOverviewState;
}

export default combineReducers<IDefaultState>({
  list: recommendationsListReducer,
  entities: entitiesReducer,
  create: createRecommendationReducer,
  edit: editRecommendationReducer,
  remove: removeRecommendationReducer,
  table: recommendationsTableReducer,
  overview: recommendationsOverviewReducer,
});
