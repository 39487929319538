import { createAction } from 'redux-actions';
// types
import { Api, IListOptions } from '@healthplate/types';
import { IListMeta, INormalizedListPayload } from 'services/types';

// get
export const getTags = createAction<Api.Tag.IGetTagListOptions>('GET_TAGS');
export const getTagsSuccess = createAction<INormalizedListPayload, IListMeta>(
  'GET_TAGS_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getTagsFail = createAction('GET_TAGS_FAIL');
// update
export const editTag = createAction<string>('EDIT_TAG');
export const editTagCancel = createAction('EDIT_TAG_CANCEL');
// remove
export const removeTags = createAction<string[]>('REMOVE_TAGS');
export const removeTagsSuccess = createAction<string[]>('REMOVE_TAGS_SUCCESS');
export const removeTagsFail = createAction('REMOVE_TAGS_FAIL');
// table
export const selectTagRow = createAction<string>('SELECT_TAG_ROW');
export const setSelectedTagsRow = createAction<string[]>(
  'SET_SELECTED_TAGS_ROW',
);
export const setTableOptions = createAction<IListOptions>(
  'SET_TAG_TABLE_OPTIONS',
);
// search
export const getTagsBySearch = createAction<string>('GET_TAGS_BY_SEARCH');
export const getTagsBySearchSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_TAGS_BY_SEARCH_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getTagsBySearchFail = createAction('GET_TAGS_BY_SEARCH_FAIL');
