import { createSelector } from 'reselect';
// models
import { Category, ListCategories } from 'models/Category';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import { IDefaultState as ICategoriesState } from 'modules/category/reducers';
import { IListOptions, Models } from '@healthplate/types';
import { IAllEntitiesState } from 'modules/core/selectors';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const CategoriesState = ({ category }: State) => category;

export const getCategoriesListNormalized = createSelector<
  State,
  ICategoriesState,
  string[]
>(CategoriesState, prop('list.data'));

export const getCategoriesList = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Category.ICategory[]
>(getCategoriesListNormalized, getAllEntities, denormalizeProp(ListCategories));

export const selectPaginationNextUrl = createSelector<
  State,
  ICategoriesState,
  string | null
>(CategoriesState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  ICategoriesState,
  string | null
>(CategoriesState, prop('list.prevUrl'));

export const selectCategoriesByIdArray = (idArray: string[]) =>
  createSelector<
    State,
    string[],
    IAllEntitiesState,
    Models.Category.ICategory[]
  >(() => idArray, getAllEntities, denormalizeProp(ListCategories));

export const selectListCategoriesTotalCount = createSelector<
  State,
  ICategoriesState,
  number
>(CategoriesState, prop('list.totalCount'));

export const selectIsListCategoriesLoading = createSelector<
  State,
  ICategoriesState,
  boolean
>(CategoriesState, prop('list.isLoading'));

export const getEditCategoryId = createSelector<
  State,
  ICategoriesState,
  string | null
>(CategoriesState, prop('edit.categoryId'));

export const getEditCategory = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Category.ICategory | null
>(getEditCategoryId, getAllEntities, denormalizeProp(Category));

export const getSelectedCategoriesRows = createSelector<
  State,
  ICategoriesState,
  string[]
>(CategoriesState, prop('table.selectedRows'));

export const selectTableOptions = createSelector<
  State,
  ICategoriesState,
  IListOptions
>(CategoriesState, prop('table.options'));

export const selectCategoriesSearchListNormalized = createSelector<
  State,
  ICategoriesState,
  string[]
>(CategoriesState, prop('search.data'));

export const selectCategoriesSearchList = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Category.ICategory[]
>(
  selectCategoriesSearchListNormalized,
  getAllEntities,
  denormalizeProp(ListCategories),
);
