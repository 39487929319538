import { createAction } from 'redux-actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import { IListMeta, INormalizedListPayload } from 'services/types';
import { IFormValues as IRecipeFormValues } from 'modules/recipe/containers/RecipeManageForm';

// get
export const getListRecipes = createAction<IListOptions>('GET_LIST_RECIPES');
export const getListRecipesSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_LIST_RECIPES_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListRecipesFail = createAction<IListOptions>(
  'GET_LIST_RECIPES_FAIL',
);
// create
export const createRecipe = createAction<IRecipeFormValues>('CREATE_RECIPE');
export const createRecipeSuccess = createAction('CREATE_RECIPE_SUCCESS');
export const createRecipeFail = createAction('CREATE_RECIPE_FAIL');
// edit
export const editRecipe = createAction<string>('EDIT_RECIPE');
export const editRecipeCancel = createAction('EDIT_RECIPE_CANCEL');
export const updateRecipe = createAction<Models.Recipe.IRecipe>(
  'UPDATE_RECIPE',
);
export const updateRecipeSuccess = createAction('UPDATE_RECIPE_SUCCESS');
export const updateRecipeFail = createAction('UPDATE_RECIPE_FAIL');
// remove
export const removeRecipes = createAction<string[]>('REMOVE_RECIPES');
export const removeRecipesSuccess = createAction('REMOVE_RECIPES_SUCCESS');
export const removeRecipesFail = createAction('REMOVE_RECIPES_FAIL');
// table
export const selectRecipeRow = createAction<string>('SELECT_RECIPE_ROW');
export const setSelectedRecipesRow = createAction<string[]>(
  'SET_SELECTED_RECIPE_ROW',
);
export const setRecipeTableOptions = createAction<IListOptions>(
  'SET_RECIPE_TABLE_OPTIONS',
);
