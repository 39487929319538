import { createSelector } from 'reselect';
// types
import { State } from 'modules/reducers';
import { IDefaultState as IAuthState } from 'modules/auth/reducers';
// helpers
import { prop } from 'services/helpers';

const AuthState = ({ auth }: State) => auth;

export const selectToken = createSelector<State, IAuthState, string | null>(
  AuthState,
  prop('token.value'),
);
