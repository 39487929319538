import React from 'react';
// icons
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import AppsIcon from '@material-ui/icons/Apps';
import LabelIcon from '@material-ui/icons/Label';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import StarIcon from '@material-ui/icons/Star';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

export interface IMenuLink {
  title: string;
  link: string;
  icon: React.FC;
}

export const siteLinks: IMenuLink[] = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    icon: HomeIcon,
  },
  {
    title: 'Users',
    link: '/users',
    icon: PeopleIcon,
  },
  {
    title: 'Categories',
    link: '/categories',
    icon: AppsIcon,
  },
  {
    title: 'Tags',
    link: '/tags',
    icon: LabelIcon,
  },
  {
    title: 'Foods',
    link: '/foods',
    icon: FastfoodIcon,
  },
  {
    title: 'Recommendations',
    link: '/recommendations',
    icon: StarIcon,
  },
  {
    title: 'Recipes',
    link: '/recipes',
    icon: ReceiptIcon,
  },
  {
    title: 'Articles',
    link: '/articles',
    icon: ChromeReaderModeIcon,
  },
];

export const additionalLinks: IMenuLink[] = [];
