import { Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import Spinner from 'modules/core/components/Spinner';

const routes = [
  {
    Component: Redirect,
    props: {
      from: '/',
      to: '/dashboard',
      exact: true,
    },
  },
  {
    Component: Route,
    props: {
      path: '/tags',
      component: Loadable({
        loader: () => import('./Tags'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/categories',
      component: Loadable({
        loader: () => import('./Categories'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/recipes',
      component: Loadable({
        loader: () => import('./Recipes'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/recommendations',
      component: Loadable({
        loader: () => import('./Recommendations'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/foods',
      component: Loadable({
        loader: () => import('./Foods'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/articles',
      component: Loadable({
        loader: () => import('./Articles'),
        loading: Spinner,
      }),
    },
  },
  {
    Component: Route,
    props: {
      path: '/dashboard',
      component: Loadable({
        loader: () => import('./Dashboard'),
        loading: Spinner,
      }),
      exact: true,
      title: 'Dashboard',
    },
  },
  {
    Component: Route,
    props: {
      path: '/users',
      component: Loadable({
        loader: () => import('./Users'),
        loading: Spinner,
      }),
      exact: true,
      title: 'Users',
    },
  },
  {
    Component: Route,
    props: {
      path: '/users/:id',
      component: Loadable({
        loader: () => import('./User'),
        loading: Spinner,
      }),
      exact: true,
      title: 'Users',
    },
  },
  {
    Component: Route,
    props: {
      path: '*',
      component: Loadable({
        loader: () => import('./404'),
        loading: Spinner,
      }),
      to: '/404',
      title: 'Error 404',
    },
  },
];

export default routes;
