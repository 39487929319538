import { denormalize, Schema } from 'normalizr';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

export const genUUID = () =>
  Math.random()
    .toString(36)
    .substr(2, 9);

export const userFilter = (filter: number) => {
  switch (filter) {
    case 0:
      return 'all';
    case 1:
      return 'premium';
    case 2:
      return 'basic';
    case 3:
      return 'free';
  }
};

export const isString = (x: any) => {
  return Object.prototype.toString.call(x) === '[object String]';
};

const isInteger =
  Number.isInteger ||
  function isInteger(n) {
    // @ts-ignore
    return n << 0 === n;
  };

export const nth = (offset: any, list: any) => {
  const idx = offset < 0 ? list.length + offset : offset;
  return isString(list) ? list.charAt(idx) : list[idx];
};

export const paths = (pathsArray: any[], obj: any): any => {
  return pathsArray.map((paths) => {
    let val = obj;
    let idx = 0;
    let p;

    while (idx < paths.length) {
      if (val == null) {
        return undefined;
      }
      p = paths[idx];
      val = isInteger(p) ? nth(p, val) : val[p];
      idx += 1;
    }

    return val;
  });
};

export const path = (pathAr: any[], obj: any) => {
  return paths([pathAr], obj)[0];
};

export const prop = <T = any>(p: string) => (obj: any): T => {
  const paths = p.split('.');

  return path(paths, obj);
};

export const denormalizeProp = (schema: Schema) => (
  input: any,
  entities: any,
) => {
  return denormalize(input, schema, entities);
};

export function debounce(func: Function, wait = 166) {
  let timeout: any;
  function debounced(...args: any[]) {
    // @ts-ignore
    const that = this;
    const later = () => {
      func.apply(that, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export const clearReps = <T>(array: T[]): T[] => Array.from(new Set(array));

export const parseEntityDependencyObjectsToId = (
  entity: any,
  schema: Schema,
) => {
  const updatedEntity = { ...entity };
  // @ts-ignore
  const dependencies = Object.keys(schema.schema);

  dependencies.forEach((dependency) => {
    const dependencyValue = updatedEntity[dependency];

    if (Array.isArray(dependencyValue)) {
      updatedEntity[dependency] = dependencyValue.map((item) => item._id);
    } else if (typeof dependencyValue === 'object') {
      updatedEntity[dependency] = dependencyValue._id;
    }
  });

  return updatedEntity;
};

export function editorStateToString(value: EditorState) {
  return JSON.stringify(convertToRaw(value.getCurrentContent()));
}

export function stringToEditorState(value: string) {
  try {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
  } catch (e) {
    return EditorState.createEmpty();
  }
}

export function getObjectId(obj: any): string | null {
  if (typeof obj === 'object') {
    return obj._id;
  }

  if (typeof obj === 'string') {
    return obj;
  }

  return null;
}
