import { createAction } from 'redux-actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IFormValues as IProductComponentFormValues } from 'modules/productComponent/containers/ProductComponentManageForm';

// get
export const getListProductComponents = createAction<IListOptions>(
  'GET_LIST_PRODUCT_COMPONENTS',
);
export const getListProductComponentsSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_LIST_PRODUCT_COMPONENTS_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListProductComponentsFail = createAction(
  'GET_LIST_PRODUCT_COMPONENTS_FAIL',
);
// create
export const createProductComponent = createAction<IProductComponentFormValues>(
  'CREATE_PRODUCT_COMPONENT',
);
export const createProductComponentSuccess = createAction(
  'CREATE_PRODUCT_COMPONENT_SUCCESS',
);
export const createProductComponentFail = createAction(
  'CREATE_PRODUCT_COMPONENT_FAIL',
);
// edit
export const editProductComponent = createAction<string>(
  'EDIT_PRODUCT_COMPONENT',
);
export const editProductComponentCancel = createAction(
  'EDIT_PRODUCT_COMPONENT_CANCEL',
);
export const updateProductComponent = createAction<
  Models.ProductComponent.IProductComponent
>('UPDATE_PRODUCT_COMPONENT');
export const updateProductComponentSuccess = createAction<
  INormalizedItemPayload
>('UPDATE_PRODUCT_COMPONENT_SUCCESS');
export const updateProductComponentFail = createAction(
  'UPDATE_PRODUCT_COMPONENT_FAIL',
);
// remove
export const removeProductComponents = createAction<string[]>(
  'REMOVE_PRODUCT_COMPONENTS',
);
export const removeProductComponentsSuccess = createAction(
  'REMOVE_PRODUCT_COMPONENTS_SUCCESS',
);
export const removeProductComponentsFail = createAction(
  'REMOVE_PRODUCT_COMPONENTS_FAIL',
);
// table
export const selectProductComponentRow = createAction<string>(
  'SELECT_PRODUCT_COMPONENT_ROW',
);
export const setSelectedProductComponentsRow = createAction<string[]>(
  'SET_SELECTED_PRODUCT_COMPONENTS_ROW',
);
export const setProductComponentsTableOptions = createAction<IListOptions>(
  'SET_PRODUCT_COMPONENTS_TABLE_OPTIONS',
);
// search
export const getProductComponentsBySearch = createAction<string>(
  'GET_PRODUCT_COMPONENTS_BY_SEARCH',
);
export const getProductComponentsBySearchSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_PRODUCT_COMPONENTS_BY_SEARCH_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getProductComponentsBySearchFail = createAction(
  'GET_PRODUCT_COMPONENTS_BY_SEARCH_FAIL',
);
