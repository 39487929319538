import { put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { AxiosError } from 'axios';
// actions
import { addAlert } from 'modules/core/actions';

function* onError({ payload, error }: Action<AxiosError>) {
  if (error && payload) {
    const errorMessage = payload.response
      ? payload.response.data.message
      : payload.message;
    yield put(
      addAlert({
        type: 'error',
        message: errorMessage,
      }),
    );
  }
}

export default function* initCoreSagas() {
  yield takeEvery('*', onError);
}
