import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    spinnerWrapper: {
      position: 'absolute',
      display: 'flex',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
);

interface ISpinnerProps {
  isLoading: boolean;
  size?: number;
}

const Spinner: React.FC<ISpinnerProps> = ({
  isLoading,
  children,
  size = 50,
}) => {
  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <div className={classes.spinnerWrapper}>
          <CircularProgress size={size} thickness={3} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Spinner;
