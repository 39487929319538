import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import 'normalize.css';
// containers
import Auth0Provider from 'modules/auth/containers/Auth0Provider';
import ThemeProvider from 'modules/core/containers/ThemeProvider';
// services
import store from 'services/store';
import getHistory from 'services/history';
// layouts
import AppLayout from 'pages/Layout';

const history = getHistory();
const queryClient = new QueryClient();

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router history={history}>
        <Auth0Provider>
          <ThemeProvider>
            <AppLayout />
          </ThemeProvider>
        </Auth0Provider>
      </Router>
    </Provider>
  </QueryClientProvider>
);

export default App;
