import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getListRecipes,
  getListRecipesSuccess,
  getListRecipesFail,
  createRecipe,
  createRecipeSuccess,
  createRecipeFail,
  editRecipe,
  editRecipeCancel,
  updateRecipe,
  updateRecipeSuccess,
  updateRecipeFail,
  removeRecipes,
  removeRecipesSuccess,
  removeRecipesFail,
  selectRecipeRow,
  setSelectedRecipesRow,
  setRecipeTableOptions,
} from 'modules/recipe/actions';
// types
import {
  IEntitiesMap,
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IListOptions, Models } from '@healthplate/types';

// entities
export type IEntitiesState = IEntitiesMap<Models.Recipe.IRecipe>;

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListRecipesSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.recipes,
    }),
    [`${updateRecipeSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.recipes[payload.result],
    }),
  },
  entitiesDefaultState,
);

// list
interface IRecipeListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const recipeListDefaultState: IRecipeListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const recipeListReducer = handleActions<IRecipeListState, any, IListMeta>(
  {
    [`${getListRecipes}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListRecipesSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getListRecipesFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  recipeListDefaultState,
);

// create
interface ICreateRecipeState {
  isLoading: boolean;
}

const createRecipeDefaultState: ICreateRecipeState = {
  isLoading: false,
};

const createRecipeReducer = handleActions<ICreateRecipeState>(
  {
    [`${createRecipe}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createRecipeSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createRecipeFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createRecipeDefaultState,
);

// edit
interface IEditRecipeState {
  isLoading: boolean;
  recipeId: string | null;
}

const editRecipeDefaultState: IEditRecipeState = {
  isLoading: false,
  recipeId: null,
};

const editRecipeReducer = handleActions<IEditRecipeState, string>(
  {
    [`${editRecipe}`]: (state, { payload }) => ({
      ...state,
      recipeId: payload,
    }),
    [`${editRecipeCancel}`]: (state) => ({
      ...state,
      recipeId: null,
    }),
    [`${updateRecipe}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateRecipeSuccess}`]: (state) => ({
      ...state,
      recipeId: null,
      isLoading: false,
    }),
    [`${updateRecipeFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editRecipeDefaultState,
);

// remove
interface IRemoveRecipeState {
  isLoading: boolean;
}

const removeRecipeDefaultState: IRemoveRecipeState = {
  isLoading: false,
};

const removeRecipeReducer = handleActions<IRemoveRecipeState>(
  {
    [`${removeRecipes}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeRecipesSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeRecipesFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeRecipeDefaultState,
);

// table
interface IRecipesTableState {
  selectedRows: string[];
  options: IListOptions;
}

const recipesTableDefaultState: IRecipesTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const recipesTableReducer = handleActions<IRecipesTableState, any>(
  {
    [`${selectRecipeRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedRecipesRow}`]: (state, { payload }: Action<string[]>) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setRecipeTableOptions}`]: (
      state,
      { payload }: Action<IListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  recipesTableDefaultState,
);

export interface IDefaultState {
  readonly list: IRecipeListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateRecipeState;
  readonly edit: IEditRecipeState;
  readonly remove: IRemoveRecipeState;
  readonly table: IRecipesTableState;
}

export default combineReducers({
  list: recipeListReducer,
  entities: entitiesReducer,
  create: createRecipeReducer,
  edit: editRecipeReducer,
  remove: removeRecipeReducer,
  table: recipesTableReducer,
});
