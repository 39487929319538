import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, Switch } from 'react-router-dom';
import routes from 'pages/routes';
import { makeStyles, CssBaseline } from '@material-ui/core';
// containers
import { useAuth0 } from 'modules/auth/containers/Auth0Provider';
import Header from 'modules/core/containers/Header';
// components
import Spinner from 'modules/core/components/Spinner';
import Menu from 'modules/core/containers/Menu';
import Content from 'modules/core/components/Content';
import Alerts from 'modules/core/containers/Alerts';
// selectors
import { selectToken } from 'modules/auth/selectors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const AppLayout: React.FC<RouteProps> = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const token = useSelector(selectToken);
  const classes = useStyles();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <>
      <Spinner isLoading={!isAuthenticated || !token}>
        <div className={classes.root}>
          <CssBaseline />
          <Header />
          <Menu />
          <Content>
            <Switch>
              {routes.map((Route: any, key) => (
                <Route.Component key={key} {...Route.props} />
              ))}
            </Switch>
          </Content>
          <Alerts />
        </div>
      </Spinner>
    </>
  );
};

export default AppLayout;
