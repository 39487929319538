import {
  call,
  put,
  select,
  takeEvery,
  getContext,
  all,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import { Article, ListArticles } from 'models/Article';
// selectors
import { selectArticlesTableOptions } from 'modules/article/selectors';
// actions
import {
  getListArticles,
  getListArticlesSuccess,
  getListArticlesFail,
  createArticle,
  createArticleSuccess,
  createArticleFail,
  updateArticle,
  updateArticleSuccess,
  updateArticleFail,
  removeArticles,
  removeArticlesSuccess,
  removeArticlesFail,
} from 'modules/article/actions';
import { addAlert } from 'modules/core/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import { IFormValues as IArticleFormValues } from 'modules/article/containers/ArticleManageForm';
import { IEntities } from 'services/types';
// helpers
import { editorStateToString } from 'services/helpers';

function* onGetListArticles({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.articles.list, {
      ...payload,
    });

    const normalizedPayload = normalize(result.data, ListArticles);

    yield put(
      getListArticlesSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getListArticlesFail(e));
  }
}

function* onCreateArticle({ payload }: Action<IArticleFormValues>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.articles.create, {
      ...payload,
      content: editorStateToString(payload.content),
    });
    const options = yield select(selectArticlesTableOptions);

    const normalizedPayload = normalize<Models.Article.IArticle, IEntities>(
      result,
      Article,
    );

    yield all([
      put(createArticleSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Article "${payload.title}" successfully created`,
        }),
      ),
      put(getListArticles(options)),
    ]);
  } catch (e) {
    yield put(createArticleFail(e));
  }
}

function* onUpdateArticle({ payload }: Action<Models.Article.IArticle>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.articles.update, payload._id, payload);

    const normalizedPayload = normalize(result, Article);

    yield all([
      put(updateArticleSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Article "${payload.title}" successfully updated`,
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateArticleFail(e));
  }
}

function* onRemoveArticles({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectArticlesTableOptions);

    yield all(payload.map((articleId) => call(api.articles.delete, articleId)));

    yield all([
      put(removeArticlesSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Articles successfully removed',
        }),
      ),
      put(getListArticles(options)),
    ]);
  } catch (e) {
    yield put(removeArticlesFail(e));
  }
}

export default function* initArticleSagas() {
  yield takeEvery(getListArticles, onGetListArticles);
  yield takeEvery(createArticle, onCreateArticle);
  yield takeEvery(updateArticle, onUpdateArticle);
  yield takeEvery(removeArticles, onRemoveArticles);
}
