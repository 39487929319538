import { createSelector } from 'reselect';
// models
import { ListArticles, Article } from 'models/Article';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import { IDefaultState as IArticleState } from 'modules/article/reducers';
import { IAllEntitiesState } from 'modules/core/selectors';
import { IListOptions, Models } from '@healthplate/types';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const ArticleState = ({ article }: State) => article;

export const selectListArticlesNormalized = createSelector<
  State,
  IArticleState,
  string[]
>(ArticleState, prop('list.data'));

export const selectListArticles = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Article.IArticle[]
>(selectListArticlesNormalized, getAllEntities, denormalizeProp(ListArticles));

export const selectPaginationNextUrl = createSelector<
  State,
  IArticleState,
  string | null
>(ArticleState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  IArticleState,
  string | null
>(ArticleState, prop('list.prevUrl'));

export const selectListArticlesTotalCount = createSelector<
  State,
  IArticleState,
  number
>(ArticleState, prop('list.totalCount'));

export const selectEditArticleId = createSelector<
  State,
  IArticleState,
  string | null
>(ArticleState, prop('edit.articleId'));

export const selectEditArticle = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Article.IArticle
>(selectEditArticleId, getAllEntities, denormalizeProp(Article));

export const selectSelectedArticlesRows = createSelector<
  State,
  IArticleState,
  string[]
>(ArticleState, prop('table.selectedRows'));

export const selectArticlesTableOptions = createSelector<
  State,
  IArticleState,
  IListOptions
>(ArticleState, prop('table.options'));

export const selectIsListArticlesLoading = createSelector<
  State,
  IArticleState,
  boolean
>(ArticleState, prop('list.isLoading'));
