import { schema } from 'normalizr';
// models
import { ListCategories } from 'models/Category';
import { ListTags } from 'models/Tag';
import { ListProductComponents } from 'models/ProductComponent';

export const Product = new schema.Entity(
  'products',
  {
    category: ListCategories,
    tags: ListTags,
    components: ListProductComponents,
  },
  { idAttribute: '_id' },
);

export const ListProducts = [Product];

Product.define({ ingredients: ListProducts });
