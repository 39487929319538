import { schema } from 'normalizr';
import { ListTags } from 'models/Tag';

export const Category = new schema.Entity(
  'categories',
  {
    tags: ListTags,
  },
  { idAttribute: '_id' },
);

export const ListCategories = [Category];

Category.define({ parents: ListCategories });
