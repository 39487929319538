import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
// actions
import { setAccessTokenSuccess } from 'modules/auth/actions';

// token
type ITokenState = {
  value: string | null;
};

const defaultTokenState: ITokenState = {
  value: null,
};

const tokenReducer = handleActions<ITokenState, string>(
  {
    [`${setAccessTokenSuccess}`]: (state, { payload }) => ({
      ...state,
      value: payload,
    }),
  },
  defaultTokenState,
);

export interface IDefaultState {
  token: ITokenState;
}

export default combineReducers({
  token: tokenReducer,
});
