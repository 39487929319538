import React from 'react';
import { makeStyles } from '@material-ui/core';
// material ui components
import { Box, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  footer: {
    marginTop: 'auto',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footer} pt={4}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" target="_blank" href="https://seedium.io">
          Seedium
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};

export default Footer;
