import { createSelector } from 'reselect';
// models
import { ListProducts, Product } from 'models/Product';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import {
  IDefaultState as IProductState,
  IProductsOverviewInfo,
} from 'modules/product/reducers';
import { IListOptions, Models } from '@healthplate/types';
import { IAllEntitiesState } from 'modules/core/selectors';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const ProductState = ({ product }: State) => product;

export const selectListProductsNormalized = createSelector<
  State,
  IProductState,
  string[]
>(ProductState, prop('list.data'));

export const selectListProducts = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Product.IProduct[]
>(selectListProductsNormalized, getAllEntities, denormalizeProp(ListProducts));

export const selectPaginationNextUrl = createSelector<
  State,
  IProductState,
  string | null
>(ProductState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  IProductState,
  string | null
>(ProductState, prop('list.prevUrl'));

export const getSelectedProductsRows = createSelector<
  State,
  IProductState,
  string[]
>(ProductState, prop('table.selectedRows'));

export const selectProductsTableOptions = createSelector<
  State,
  IProductState,
  IListOptions
>(ProductState, prop('table.options'));

export const selectProductsSearchListNormalized = createSelector<
  State,
  IProductState,
  string[]
>(ProductState, prop('search.data'));

export const selectProductsSearchList = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Product.IProduct[]
>(
  selectProductsSearchListNormalized,
  getAllEntities,
  denormalizeProp(ListProducts),
);

export const selectIsProductsLoading = createSelector<
  State,
  IProductState,
  boolean
>(ProductState, prop('list.isLoading'));

export const selectListProductsTotalCount = createSelector<
  State,
  IProductState,
  number
>(ProductState, prop('list.totalCount'));

export const selectEditProductId = createSelector<
  State,
  IProductState,
  string | null
>(ProductState, prop('edit.productId'));

export const selectEditProduct = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Product.IProduct | null
>(selectEditProductId, getAllEntities, denormalizeProp(Product));

export const selectProductsOverview = createSelector<
  State,
  IProductState,
  IProductsOverviewInfo
>(ProductState, prop('overview'));

export const selectIsProductOverviewLoading = createSelector<
  State,
  IProductState,
  boolean
>(ProductState, prop('overview.isLoading'));

export const selectProductsByIdArray = (idArray: string[]) =>
  createSelector<State, string[], IAllEntitiesState, Models.Product.IProduct[]>(
    () => idArray,
    getAllEntities,
    denormalizeProp(ListProducts),
  );

export const selectProductById = (id: string | undefined) =>
  createSelector<
    State,
    string | undefined,
    IAllEntitiesState,
    Models.Product.IProduct | null
  >(() => id, getAllEntities, denormalizeProp(Product));

export const selectProductIngredients = (id: string | undefined) =>
  createSelector<
    State,
    Models.Product.IProduct | null,
    Models.Product.IProduct[] | []
  >(selectProductById(id), (product) => product?.ingredients ?? []);

export const selectProductComponents = (id: string | undefined) =>
  createSelector<
    State,
    Models.Product.IProduct | null,
    Models.ProductComponent.IProductComponent[] | []
  >(selectProductById(id), (product) => product?.components ?? []);
