import { createSelector } from 'reselect';
// models
import { Tag, ListTags } from 'models/Tag';
// selectors
import { getAllEntities } from 'modules/core/selectors';
// types
import { State } from 'modules/reducers';
import { IDefaultState as ITagsState } from 'modules/tag/reducers';
import { IListOptions, Models } from '@healthplate/types';
import { IAllEntitiesState } from 'modules/core/selectors';
// helpers
import { denormalizeProp, prop } from 'services/helpers';

const TagsState = ({ tag }: State) => tag;

export const getTagsListNormalized = createSelector<
  State,
  ITagsState,
  string[]
>(TagsState, prop('list.data'));

export const getListTags = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Tag.ITag[]
>(getTagsListNormalized, getAllEntities, denormalizeProp(ListTags));

export const selectPaginationNextUrl = createSelector<
  State,
  ITagsState,
  string | null
>(TagsState, prop('list.nextUrl'));

export const selectPaginationPrevUrl = createSelector<
  State,
  ITagsState,
  string | null
>(TagsState, prop('list.prevUrl'));

export const selectTagsByIdArray = (idArray: string[]) =>
  createSelector<State, string[], IAllEntitiesState, Models.Tag.ITag[]>(
    () => idArray,
    getAllEntities,
    denormalizeProp(ListTags),
  );

export const getListTagsTotalCount = createSelector<State, ITagsState, number>(
  TagsState,
  prop('list.totalCount'),
);

export const isTagsLoading = createSelector<State, ITagsState, boolean>(
  TagsState,
  prop('list.isLoading'),
);

export const selectEditTagId = createSelector<State, ITagsState, string | null>(
  TagsState,
  prop('edit.tagId'),
);

export const getEditTag = createSelector<
  State,
  string | null,
  IAllEntitiesState,
  Models.Tag.ITag | undefined
>(selectEditTagId, getAllEntities, denormalizeProp(Tag));

export const isTagEditLoading = createSelector<State, ITagsState, boolean>(
  TagsState,
  prop('edit.isLoading'),
);

export const isTagCreateLoading = createSelector<State, ITagsState, boolean>(
  TagsState,
  prop('create.isLoading'),
);

export const isTagsRemoveLoading = createSelector<State, ITagsState, boolean>(
  TagsState,
  prop('remove.isLoading'),
);

export const getSelectedTagsRows = createSelector<State, ITagsState, string[]>(
  TagsState,
  prop('table.selectedRows'),
);

export const selectTableOptions = createSelector<
  State,
  ITagsState,
  IListOptions
>(TagsState, prop('table.options'));

export const selectTagsSearchListNormalized = createSelector<
  State,
  ITagsState,
  string[]
>(TagsState, prop('search.data'));

export const selectTagsSearchList = createSelector<
  State,
  string[],
  IAllEntitiesState,
  Models.Tag.ITag[]
>(selectTagsSearchListNormalized, getAllEntities, denormalizeProp(ListTags));
