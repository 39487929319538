import { combineReducers } from 'redux';
import { Action, ActionMeta, handleActions } from 'redux-actions';
// actions
import {
  getListProducts,
  getListProductsSuccess,
  getListProductsFail,
  createProduct,
  createProductSuccess,
  createProductFail,
  selectProductRow,
  setSelectedProductsRow,
  setProductsTableOptions,
  getProductsBySearch,
  getProductsBySearchSuccess,
  getProductsBySearchFail,
  removeProducts,
  removeProductsSuccess,
  removeProductsFail,
  editProduct,
  editProductCancel,
  updateProduct,
  updateProductSuccess,
  updateProductFail,
  getProductsOverviewInfo,
  getProductsOverviewInfoSuccess,
  getProductsOverviewInfoFail,
  getProductSuccess,
} from 'modules/product/actions';
import { getListRecommendationsSuccess } from 'modules/recommendation/actions';
// types
import {
  IEntitiesMap,
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';
import { IListOptions, Models } from '@healthplate/types';

// entities
export type IEntitiesState = IEntitiesMap<Models.Product.IProduct>;

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListProductsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.products,
    }),
    [`${updateProductSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.products[payload.result],
    }),
    [`${getProductsBySearchSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.products,
    }),
    [`${getProductSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      [payload.result]: payload.entities.products[payload.result],
    }),
    [`${getListRecommendationsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.products,
    }),
  },
  entitiesDefaultState,
);

// list
interface IProductsListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const productsListDefaultState: IProductsListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const productsListReducer = handleActions<IProductsListState, any, IListMeta>(
  {
    [`${getListProducts}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListProductsSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getListProductsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  productsListDefaultState,
);

// create
interface ICreateProductState {
  isLoading: boolean;
}

const createProductDefaultState: ICreateProductState = {
  isLoading: false,
};

const createProductReducer = handleActions<ICreateProductState>(
  {
    [`${createProduct}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${createProductSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${createProductFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  createProductDefaultState,
);

// edit
interface IEditProductState {
  readonly productId: string | null;
  readonly isLoading: boolean;
}

const editProductDefaultState: IEditProductState = {
  productId: null,
  isLoading: false,
};

const editProductReducer = handleActions<IEditProductState, string>(
  {
    [`${editProduct}`]: (state, { payload }) => ({
      ...state,
      productId: payload,
    }),
    [`${editProductCancel}`]: (state) => ({
      ...state,
      productId: null,
    }),
    [`${updateProduct}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${updateProductSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
      productId: null,
    }),
    [`${updateProductFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  editProductDefaultState,
);

// remove
interface IRemoveProductState {
  readonly isLoading: boolean;
}

const removeProductDefaultState: IRemoveProductState = {
  isLoading: false,
};

const removeProductsReducer = handleActions<IRemoveProductState>(
  {
    [`${removeProducts}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeProductsSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeProductsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeProductDefaultState,
);

// table
interface IProductsTableState {
  selectedRows: string[];
  options: IListOptions;
}

const productsTableState: IProductsTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const productsTableReducer = handleActions<IProductsTableState, any>(
  {
    [`${selectProductRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedProductsRow}`]: (state, { payload }: Action<string[]>) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setProductsTableOptions}`]: (
      state,
      { payload }: Action<IListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  productsTableState,
);

// search
interface IProductsSearchState {
  isLoading: boolean;
  data: string[];
  totalCount?: number;
}

const productsSearchDefaultState: IProductsSearchState = {
  isLoading: false,
  data: [],
  totalCount: 0,
};

const productsSearchReducer = handleActions<
  IProductsSearchState,
  INormalizedListPayload,
  IListMeta
>(
  {
    [`${getProductsBySearch}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getProductsBySearchSuccess}`]: (state, { payload, meta }) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      isLoading: false,
    }),
    [`${getProductsBySearchFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  productsSearchDefaultState,
);

// overview
export interface IProductsOverviewInfo {
  primaryValue: number;
  datasets: { label: string; value: number }[];
}

interface IProductsOverviewState extends IProductsOverviewInfo {
  isLoading: boolean;
}

const productsOverviewDefaultState: IProductsOverviewState = {
  isLoading: false,
  primaryValue: 0,
  datasets: [],
};

const productsOverviewReducer = handleActions<IProductsOverviewState, any>(
  {
    [`${getProductsOverviewInfo}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getProductsOverviewInfoSuccess}`]: (
      state,
      { payload }: Action<IProductsOverviewInfo>,
    ) => ({
      ...state,
      ...payload,
      isLoading: false,
    }),
    [`${getProductsOverviewInfoFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  productsOverviewDefaultState,
);

export interface IDefaultState {
  readonly list: IProductsListState;
  readonly entities: IEntitiesState;
  readonly create: ICreateProductState;
  readonly edit: IEditProductState;
  readonly remove: IRemoveProductState;
  readonly table: IProductsTableState;
  readonly search: IProductsSearchState;
  readonly overview: IProductsOverviewState;
}

export default combineReducers<IDefaultState>({
  list: productsListReducer,
  entities: entitiesReducer,
  create: createProductReducer,
  edit: editProductReducer,
  remove: removeProductsReducer,
  table: productsTableReducer,
  search: productsSearchReducer,
  overview: productsOverviewReducer,
});
