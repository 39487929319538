import { combineReducers } from 'redux';
import { handleActions, Action, ActionMeta } from 'redux-actions';
// actions
import {
  getTags,
  getTagsSuccess,
  getTagsFail,
  editTag,
  editTagCancel,
  removeTags,
  removeTagsSuccess,
  removeTagsFail,
  selectTagRow,
  setSelectedTagsRow,
  setTableOptions,
  getTagsBySearch,
  getTagsBySearchFail,
  getTagsBySearchSuccess,
} from 'modules/tag/actions';
import { getCategoriesSuccess } from 'modules/category/actions';
import {
  getListProductsSuccess,
  getProductSuccess,
} from 'modules/product/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import {
  IListMeta,
  INormalizedItemPayload,
  INormalizedListPayload,
} from 'services/types';

// entities
export interface IEntitiesState {
  readonly [id: string]: Models.Tag.ITag;
}

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getTagsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.tags,
    }),
    [`${getTagsBySearchSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.tags,
    }),
    [`${getCategoriesSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.tags,
    }),
    [`${getListProductsSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.tags,
    }),
    [`${getProductSuccess}`]: (
      state,
      { payload }: Action<INormalizedItemPayload>,
    ) => ({
      ...state,
      ...payload.entities.tags,
    }),
  },
  entitiesDefaultState,
);

// list
interface ITagsListState {
  readonly isLoading: boolean;
  readonly data: string[];
  readonly prevUrl: string | null;
  readonly nextUrl: string | null;
  readonly totalCount?: number;
}

const tagsListDefaultState: ITagsListState = {
  isLoading: false,
  data: [],
  prevUrl: null,
  nextUrl: null,
  totalCount: 0,
};

const tagsListReducer = handleActions<ITagsListState, any, IListMeta>(
  {
    [`${getTags}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getTagsSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IListMeta>,
    ) => ({
      ...state,
      data: payload.result,
      totalCount: meta.totalCount,
      prevUrl: meta.prevUrl,
      nextUrl: meta.nextUrl,
      isLoading: false,
    }),
    [`${getTagsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  tagsListDefaultState,
);

// edit
interface IEditTagState {
  readonly tagId: string | null;
}

const editTagDefaultState: IEditTagState = {
  tagId: null,
};

const editTagReducer = handleActions<IEditTagState, string>(
  {
    [`${editTag}`]: (state, { payload }) => ({
      ...state,
      tagId: payload,
    }),
    [`${editTagCancel}`]: (state) => ({
      ...state,
      tagId: null,
    }),
  },
  editTagDefaultState,
);

// remove
interface IRemoveTagState {
  readonly isLoading: boolean;
}

const removeTagDefaultState: IRemoveTagState = {
  isLoading: false,
};

const removeTagReducer = handleActions<IRemoveTagState>(
  {
    [`${removeTags}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${removeTagsSuccess}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${removeTagsFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  removeTagDefaultState,
);

// table
interface ITagTableState {
  readonly selectedRows: string[];
  readonly options: IListOptions;
}

const tagTableDefaultState: ITagTableState = {
  selectedRows: [],
  options: {
    include: ['totalCount'],
    limit: 10,
  },
};

const tagTableReducer = handleActions<ITagTableState, any>(
  {
    [`${selectTagRow}`]: (state, { payload }: Action<string>) => ({
      ...state,
      selectedRows: state.selectedRows.includes(payload)
        ? state.selectedRows.filter((id) => id !== payload)
        : [...state.selectedRows, payload],
    }),
    [`${setSelectedTagsRow}`]: (state, { payload }: Action<string[]>) => ({
      ...state,
      selectedRows: payload,
    }),
    [`${setTableOptions}`]: (state, { payload }: Action<IListOptions>) => ({
      ...state,
      options: payload,
    }),
  },
  tagTableDefaultState,
);

// search
interface ITagsSearchState {
  readonly isLoading: boolean;
  readonly data: string[];
}

const tagsSearchDefaultState: ITagsSearchState = {
  isLoading: false,
  data: [],
};

const tagsSearchReducer = handleActions<
  ITagsSearchState,
  INormalizedListPayload
>(
  {
    [`${getTagsBySearch}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getTagsBySearchSuccess}`]: (state, { payload }) => ({
      ...state,
      data: payload.result,
      isLoading: false,
    }),
    [`${getTagsBySearchFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  tagsSearchDefaultState,
);

export interface IDefaultState {
  readonly list: ITagsListState;
  readonly entities: IEntitiesState;
  readonly edit: IEditTagState;
  readonly remove: IRemoveTagState;
  readonly table: ITagTableState;
  readonly search: ITagsSearchState;
}

export default combineReducers({
  list: tagsListReducer,
  entities: entitiesReducer,
  edit: editTagReducer,
  remove: removeTagReducer,
  table: tagTableReducer,
  search: tagsSearchReducer,
});
