import React from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
  createStyles,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Popover,
  Theme,
  Avatar,
} from '@material-ui/core';
// containers
import { useAuth0 } from 'modules/auth/containers/Auth0Provider';
// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

const commonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    trigger: {
      marginLeft: 15,
      backgroundColor: 'rgba(240, 212, 121,.6);',
      '&:hover': {
        backgroundColor: 'rgba(240, 212, 121,.8)',
      },
      boxShadow: 'none',
    },
  }),
);

const avatarStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 30,
      height: 30,
      marginRight: 10,
      paddingLeft: 1,
      backgroundColor: '#B5943F',
    },
  }),
);

const UserMenu: React.FC = () => {
  const classes = commonStyles();
  const avaClasses = avatarStyles();
  const { user, logout } = useAuth0();

  return (
    <PopupState variant="popover" popupId="user-popup">
      {(popupState) => (
        <>
          <Button
            {...bindTrigger(popupState)}
            className={classes.trigger}
            aria-controls="fade-links"
            aria-haspopup="true"
            variant="contained"
          >
            <Avatar className={avaClasses.root}>
              <PersonIcon />
            </Avatar>
            <span>{user?.name}</span>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List>
              <ListItem button={true} onClick={logout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Signout" />
              </ListItem>
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default UserMenu;
