import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
// components
import Footer from 'modules/core/components/Footer';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: {
    height: 64,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  grid: {
    width: '100%',
    margin: 0,
  },
}));

const Content: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        <Grid container={true} spacing={4} className={classes.grid}>
          {children}
        </Grid>
        <Footer />
      </Container>
    </main>
  );
};

export default Content;
