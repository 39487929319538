import {
  call,
  put,
  all,
  select,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import { ListProducts, Product } from 'models/Product';
// actions
import {
  getListProducts,
  getListProductsSuccess,
  getListProductsFail,
  createProduct,
  createProductSuccess,
  createProductFail,
  getProductsBySearchSuccess,
  getProductsBySearchFail,
  getProductsBySearch,
  removeProducts,
  removeProductsSuccess,
  removeProductsFail,
  updateProductSuccess,
  updateProductFail,
  updateProduct,
  getProductsOverviewInfo,
  getProductsOverviewInfoSuccess,
  getProductsOverviewInfoFail,
  getProductFail,
  getProduct,
  getProductSuccess,
} from 'modules/product/actions';
import { addAlert } from 'modules/core/actions';
// selectors
import { selectProductsTableOptions } from 'modules/product/selectors';
// types
import {
  IPieChartOverviewOptions,
  IListOptions,
  Models,
} from '@healthplate/types';
import { IFormValues as IProductFormValues } from 'modules/product/containers/ProductManageForm';
import { IEntities } from 'services/types';

function* onGetListProducts({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.listForAdmin, {
      ...payload,
      expand: ['components', 'tags', 'category', 'ingredients'],
    });

    const normalizedPayload = normalize(result.data, ListProducts);

    yield put(
      getListProductsSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getListProductsFail(e));
  }
}

function* onCreateProduct({ payload }: Action<IProductFormValues>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.create, {
      ...payload,
      image: payload.image || undefined,
    });
    const options = yield select(selectProductsTableOptions);

    const normalizedPayload = normalize(result, Product);

    yield all([
      put(createProductSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Product "${payload.name}" successfully created`,
        }),
      ),
      put(getListProducts(options)),
    ]);
  } catch (e) {
    yield put(createProductFail(e));
  }
}

function* onUpdateProduct({ payload }: Action<Models.Product.IProduct>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.update, payload._id, payload);

    const normalizedPayload = normalize<Models.Product.IProduct, IEntities>(
      result,
      Product,
    );

    yield all([
      put(updateProductSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Product "${payload.name}" successfully updated`,
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateProductFail(e));
  }
}

function* onRemoveProducts({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectProductsTableOptions);

    yield all(payload.map((productId) => call(api.products.delete, productId)));
    yield all([
      put(removeProductsSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Products successfully removes',
        }),
      ),
      put(getListProducts(options)),
    ]);
  } catch (e) {
    yield put(removeProductsFail(e));
  }
}

function* onSearchProducts({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.listForAdmin, {
      q: payload,
      expand: ['tags'],
    });

    const normalizedPayload = normalize(result.data, ListProducts);

    yield put(
      getProductsBySearchSuccess(normalizedPayload, {
        hasMore: result.hasMore,
      }),
    );
  } catch (e) {
    yield put(getProductsBySearchFail(e));
  }
}

function* onGetProductsOverviewInfo({
  payload,
}: Action<IPieChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.overview, payload);

    yield put(getProductsOverviewInfoSuccess(result));
  } catch (e) {
    yield put(getProductsOverviewInfoFail(e));
  }
}

function* onGetProduct({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.products.retrieve, payload, {
      expand: ['components', 'tags', 'category'],
    });

    const normalizedPayload = normalize<Models.Product.IProduct, IEntities>(
      result,
      Product,
    );

    yield put(getProductSuccess(normalizedPayload));
  } catch (e) {
    yield put(getProductFail(e));
  }
}

export default function* initProductsSagas() {
  yield takeEvery(getListProducts, onGetListProducts);
  yield takeEvery(createProduct, onCreateProduct);
  yield takeEvery(updateProduct, onUpdateProduct);
  yield takeEvery(removeProducts, onRemoveProducts);
  yield takeEvery(getProductsBySearch, onSearchProducts);
  yield takeEvery(getProductsOverviewInfo, onGetProductsOverviewInfo);
  yield takeEvery(getProduct, onGetProduct);
}
