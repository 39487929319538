import {
  call,
  put,
  select,
  takeEvery,
  getContext,
  all,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// models
import { ListRecipes, Recipe } from 'models/Recipe';
// selectors
import { selectRecipesTableOptions } from 'modules/recipe/selectors';
// actions
import {
  getListRecipes,
  getListRecipesSuccess,
  getListRecipesFail,
  createRecipe,
  createRecipeSuccess,
  updateRecipe,
  updateRecipeSuccess,
  updateRecipeFail,
  removeRecipes,
  removeRecipesSuccess,
  removeRecipesFail,
  createRecipeFail,
} from 'modules/recipe/actions';
import { addAlert } from 'modules/core/actions';
// types
import { IListOptions, Models } from '@healthplate/types';
import { IFormValues as IRecipeFormValues } from 'modules/recipe/containers/RecipeManageForm';
// helpers
import { editorStateToString } from 'services/helpers';

function* onGetListRecipes({ payload }: Action<IListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recipes.list, {
      ...payload,
    });

    const normalizedPayload = normalize(result.data, ListRecipes);

    yield put(
      getListRecipesSuccess(normalizedPayload, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getListRecipesFail(e));
  }
}

function* onCreateRecipe({ payload }: Action<IRecipeFormValues>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recipes.create, {
      ...payload,
      content: editorStateToString(payload.content),
    });
    const options = yield select(selectRecipesTableOptions);

    const normalizedPayload = normalize(result, Recipe);

    yield all([
      put(createRecipeSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Recipe "${payload.title}" successfully created`,
        }),
      ),
      put(getListRecipes(options)),
    ]);
  } catch (e) {
    yield put(createRecipeFail(e));
  }
}

function* onUpdateRecipe({ payload }: Action<Models.Recipe.IRecipe>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.recipes.update, payload._id, payload);

    const normalizedPayload = normalize(result, Recipe);

    yield all([
      put(updateRecipeSuccess(normalizedPayload)),
      put(
        addAlert({
          type: 'success',
          message: `Recipe "${payload.title}" successfully updated`,
        }),
      ),
    ]);
  } catch (e) {
    yield put(updateRecipeFail(e));
  }
}

function* onRemoveRecipes({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectRecipesTableOptions);

    yield all(payload.map((recipeId) => call(api.recipes.delete, recipeId)));

    yield all([
      put(removeRecipesSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: 'Recipes successfully removed',
        }),
      ),
      put(getListRecipes(options)),
    ]);
  } catch (e) {
    yield put(removeRecipesFail(e));
  }
}

export default function* initRecipeSagas() {
  yield takeEvery(getListRecipes, onGetListRecipes);
  yield takeEvery(createRecipe, onCreateRecipe);
  yield takeEvery(updateRecipe, onUpdateRecipe);
  yield takeEvery(removeRecipes, onRemoveRecipes);
}
