import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import createAuth0Client from '@auth0/auth0-spa-js';
// actions
import { setAccessToken, logout as logoutAction } from 'modules/auth/actions';
// config
import config from 'config';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

export const Auth0Context = React.createContext({
  isAuthenticated: false,
  user: {
    name: '',
  },
  isLoading: false,
  loginWithRedirect: () => {},
  logout: () => {},
});

export const useAuth0 = () => useContext(Auth0Context);

const Auth0Provider: React.FC = ({ children }) => {
  const [auth0Client, setAuth0] = useState<Auth0Client | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0 = await createAuth0Client({
        domain: config.auth0Domain,
        client_id: config.auth0ClientId,
        redirect_uri: window.location.origin,
        audience: config.auth0Audience,
      });

      setAuth0(auth0);

      if (window.location.search.includes('code=')) {
        await auth0.handleRedirectCallback();
      }

      const isAuthenticated = await auth0.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const [user, token] = await Promise.all([
          auth0.getUser(),
          auth0.getTokenSilently(),
        ]);
        dispatch(setAccessToken(token));
        setUser(user);
      }

      setIsLoading(false);
    };
    initAuth0();
  }, [dispatch]);

  const loginWithRedirect = async () => {
    await auth0Client.loginWithRedirect();
  };

  const logout = async () => {
    await auth0Client.logout();
    dispatch(logoutAction());
  };

  return (
    <Auth0Context.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
