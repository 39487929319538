import React from 'react';
import { NavLink, LinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
// types
import { Omit } from '@material-ui/types';

const useStyles = makeStyles(() => ({
  activeLink: {
    color: '#B79641',
    background: '#FBFBFB',
    borderRight: '4px solid #B79641',
    '& svg': {
      color: '#B79641',
    },
  },
}));

export interface ListItemLinkProps {
  icon: React.ReactElement;
  primary: string;
  to: string;
}

const MenuLink = (props: ListItemLinkProps) => {
  const classes = useStyles();
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <NavLink
          activeClassName={classes.activeLink}
          to={to}
          ref={ref}
          {...itemProps}
        />
      )),
    [to, classes],
  );

  return (
    <ListItem button={true} component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default MenuLink;
