import { combineReducers } from 'redux';

// reducers
import articleReducer, {
  IDefaultState as IArticleState,
} from 'modules/article/reducers';
import authReducer, {
  IDefaultState as IAuthState,
} from 'modules/auth/reducers';
import categoryReducer, {
  IDefaultState as ICategoryState,
} from 'modules/category/reducers';
import coreReducer, {
  IDefaultState as ICoreState,
} from 'modules/core/reducers';
import productReducer, {
  IDefaultState as IProductState,
} from 'modules/product/reducers';
import productComponentReducer, {
  IDefaultState as IProductComponentState,
} from 'modules/productComponent/reducers';
import recipeReducer, {
  IDefaultState as IRecipeState,
} from 'modules/recipe/reducers';
import recommendationReducer, {
  IDefaultState as IRecommendationState,
} from 'modules/recommendation/reducers';
import tagReducer, { IDefaultState as ITagState } from 'modules/tag/reducers';
import userReducer, {
  IDefaultState as IUserState,
} from 'modules/user/reducers';

export interface State {
  readonly article: IArticleState;
  readonly auth: IAuthState;
  readonly category: ICategoryState;
  readonly core: ICoreState;
  readonly product: IProductState;
  readonly productComponent: IProductComponentState;
  readonly recipe: IRecipeState;
  readonly recommendation: IRecommendationState;
  readonly tag: ITagState;
  readonly user: IUserState;
}

export default combineReducers<State>({
  article: articleReducer,
  auth: authReducer,
  category: categoryReducer,
  core: coreReducer,
  product: productReducer,
  productComponent: productComponentReducer,
  recipe: recipeReducer,
  recommendation: recommendationReducer,
  tag: tagReducer,
  user: userReducer,
});
