import { combineReducers } from 'redux';
import { handleActions, Action, ActionMeta } from 'redux-actions';
// actions
import {
  getListUsers,
  getListUsersSuccess,
  getListUsersFail,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFail,
  setUsersTableOptions,
  getUsersOverviewInfo,
  getUsersOverviewInfoSuccess,
  getUsersOverviewInfoFail,
  getUserHealthIndex,
  getUserHealthIndexSuccess,
  getUserHealthIndexFail,
  getUserMoodIndex,
  getUserMoodIndexSuccess,
  getUserMoodIndexFail,
  getUserComfortIndex,
  getUserComfortIndexSuccess,
  getUserComfortIndexFail,
} from 'modules/user/actions';
// types
import { Api, Models } from '@healthplate/types';
import { IEntitiesMap, INormalizedListPayload } from 'services/types';

// entities
export type IEntitiesState = IEntitiesMap<Models.User.IAuth0User>;

const entitiesDefaultState: IEntitiesState = {};

const entitiesReducer = handleActions<IEntitiesState, any>(
  {
    [`${getListUsersSuccess}`]: (
      state,
      { payload }: Action<INormalizedListPayload>,
    ) => ({
      ...state,
      ...payload.entities.users,
    }),
  },
  entitiesDefaultState,
);

// list
export interface IUsersListMeta {
  start: number;
  limit: number;
  length: number;
  total: number;
}

interface IUsersListState extends IUsersListMeta {
  isLoading: boolean;
  data: string[];
}

const listUserDefaultState: IUsersListState = {
  isLoading: false,
  data: [],
  start: 0,
  limit: 0,
  length: 0,
  total: 0,
};

const usersListReducer = handleActions<IUsersListState, any, IUsersListMeta>(
  {
    [`${getListUsers}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getListUsersSuccess}`]: (
      state,
      { payload, meta }: ActionMeta<INormalizedListPayload, IUsersListMeta>,
    ) => ({
      ...state,
      isLoading: false,
      data: payload.result,
      start: meta.start,
      limit: meta.limit,
      length: meta.length,
      total: meta.total,
    }),
    [`${getListUsersFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  listUserDefaultState,
);

// user
interface ICurrentUserState {
  isLoading: boolean;
  data: Models.User.IUser | null;
}

const currentUserDefaultState: ICurrentUserState = {
  isLoading: false,
  data: null,
};

const currentUserReducer = handleActions<ICurrentUserState, any>(
  {
    [`${getCurrentUser}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getCurrentUserSuccess}`]: (
      state,
      { payload }: Action<Models.User.IUser>,
    ) => ({ ...state, data: payload, isLoading: false }),
    [`${getCurrentUserFail}`]: (state) => ({
      ...state,
      data: null,
      isLoading: false,
    }),
  },
  currentUserDefaultState,
);

// table
interface IUsersTableState {
  options: Api.User.IGetUsersListOptions;
}

const usersTableDefaultState: IUsersTableState = {
  options: {
    limit: 10,
    page: 0,
    include: ['mood', 'health', 'comfort'],
  },
};

const usersTableReducer = handleActions<IUsersTableState, any>(
  {
    [`${setUsersTableOptions}`]: (
      state,
      { payload }: Action<Api.User.IGetUsersListOptions>,
    ) => ({
      ...state,
      options: payload,
    }),
  },
  usersTableDefaultState,
);

// overview
export interface IUsersOverviewInfo {
  primaryValue: number;
  datasets: { label: string; value: number }[];
}

interface IUsersOverviewState extends IUsersOverviewInfo {
  isLoading: boolean;
}

const usersOverviewDefaultState: IUsersOverviewState = {
  isLoading: false,
  primaryValue: 0,
  datasets: [],
};

const usersOverviewReducer = handleActions<IUsersOverviewState, any>(
  {
    [`${getUsersOverviewInfo}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getUsersOverviewInfoSuccess}`]: (
      state,
      { payload }: Action<IUsersOverviewInfo>,
    ) => ({
      ...state,
      ...payload,
      isLoading: false,
    }),
    [`${getUsersOverviewInfoFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  usersOverviewDefaultState,
);

// health index
interface IUserHealthIndex {
  isLoading: boolean;
  data: { value: number; startTime: number }[];
}

const userHealthIndexDefaultState: IUserHealthIndex = {
  isLoading: false,
  data: [],
};

const userHealthIndexReducer = handleActions<IUserHealthIndex, any>(
  {
    [`${getUserHealthIndex}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getUserHealthIndexSuccess}`]: (
      state,
      { payload }: Action<{ value: number; startTime: number }[]>,
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [`${getUserHealthIndexFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  userHealthIndexDefaultState,
);

// mood index
interface IUserMoodIndex {
  isLoading: boolean;
  data: { value: number; startTime: number }[];
}

const userMoodIndexDefaultState: IUserMoodIndex = {
  isLoading: false,
  data: [],
};

const userMoodIndexReducer = handleActions<IUserMoodIndex, any>(
  {
    [`${getUserMoodIndex}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getUserMoodIndexSuccess}`]: (
      state,
      { payload }: Action<{ value: number; startTime: number }[]>,
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [`${getUserMoodIndexFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  userMoodIndexDefaultState,
);

// comfort index
interface IUserComfortIndex {
  isLoading: boolean;
  data: { value: number; startTime: number }[];
}

const userComfortIndexDefaultState: IUserComfortIndex = {
  isLoading: false,
  data: [],
};

const userComfortIndexReducer = handleActions<IUserComfortIndex, any>(
  {
    [`${getUserComfortIndex}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getUserComfortIndexSuccess}`]: (
      state,
      { payload }: Action<{ value: number; startTime: number }[]>,
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [`${getUserComfortIndexFail}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  userComfortIndexDefaultState,
);

// current user recommendations
export interface IUserRecommendationInfo {
  date: number;
  recommendation: Models.Recommendation.IAnyRecommendation;
}

export interface IDefaultState {
  readonly entities: IEntitiesState;
  readonly list: IUsersListState;
  readonly currentUser: ICurrentUserState;
  readonly table: IUsersTableState;
  readonly overview: IUsersOverviewState;
  readonly healthIndex: IUserHealthIndex;
  readonly moodIndex: IUserMoodIndex;
  readonly comfortIndex: IUserComfortIndex;
}

export default combineReducers<IDefaultState>({
  entities: entitiesReducer,
  list: usersListReducer,
  currentUser: currentUserReducer,
  table: usersTableReducer,
  overview: usersOverviewReducer,
  healthIndex: userHealthIndexReducer,
  moodIndex: userMoodIndexReducer,
  comfortIndex: userComfortIndexReducer,
});
