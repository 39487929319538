import { createAction } from 'redux-actions';
// types
import {
  IPieChartOverviewOptions,
  IListOptions,
  Api,
  Models,
} from '@healthplate/types';
import { IListMeta, INormalizedListPayload } from 'services/types';
import { IRecommendationsOverviewInfo } from 'modules/recommendation/reducers';

// get
export const getListRecommendations = createAction<IListOptions>(
  'GET_LIST_RECOMMENDATIONS',
);
export const getListRecommendationsSuccess = createAction<
  INormalizedListPayload,
  IListMeta
>(
  'GET_LIST_RECOMMENDATIONS_SUCCESS',
  (payload) => payload,
  (_, meta) => meta,
);
export const getListRecommendationsFail = createAction(
  'GET_LIST_RECOMMENDATIONS_FAIL',
);
// create
export const createRecommendation = createAction<
  Api.Recommendation.ICreateAllTypesBody
>('CREATE_RECOMMENDATION');
export const createRecommendationSuccess = createAction(
  'CREATE_RECOMMENDATION_SUCCESS',
);
export const createRecommendationFail = createAction(
  'CREATE_RECOMMENDATION_FAIL',
);
// edit
export const editRecommendation = createAction<string>('EDIT_RECOMMENDATION');
export const editRecommendationCancel = createAction(
  'EDIT_RECOMMENDATION_CANCEL',
);
export const updateRecommendation = createAction<
  Models.Recommendation.IAnyRecommendation
>('UPDATE_RECOMMENDATION');
export const updateRecommendationSuccess = createAction(
  'UPDATE_RECOMMENDATION_SUCCESS',
);
export const updateRecommendationFail = createAction(
  'UPDATE_RECOMMENDATION_FAIL',
);
// remove
export const removeRecommendations = createAction<string[]>(
  'REMOVE_RECOMMENDATIONS',
);
export const removeRecommendationsSuccess = createAction(
  'REMOVE_RECOMMENDATIONS_SUCCESS',
);
export const removeRecommendationsFail = createAction(
  'REMOVE_RECOMMENDATIONS_FAIL',
);
// table
export const selectRecommendationRow = createAction<string>(
  'SELECT_RECOMMENDATION_ROW',
);
export const setSelectedRecommendationsRow = createAction<string[]>(
  'SET_SELECTED_RECOMMENDATIONS_ROW',
);
export const setRecommendationsTableOptions = createAction<IListOptions>(
  'SET_RECOMMENDATIONS_TABLE_OPTIONS',
);
// overview
export const getRecommendationsOverviewInfo = createAction<
  IPieChartOverviewOptions
>('GET_RECOMMENDATIONS_OVERVIEW_INFO');
export const getRecommendationsOverviewInfoSuccess = createAction<
  IRecommendationsOverviewInfo
>('GET_RECOMMENDATIONS_OVERVIEW_INFO_SUCCESS');
export const getRecommendationsOverviewInfoFail = createAction(
  'GET_RECOMMENDATIONS_OVERVIEW_INFO_FAIL',
);
