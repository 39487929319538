import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import getHistory from 'services/history';
import reducer from 'modules/reducers';
import saga from 'modules/saga';
import { apiClient } from 'services/api-client';

const history = getHistory();
const sagaMiddleware = createSagaMiddleware({
  context: {
    history,
    api: apiClient,
  },
});

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(saga);

export default store;
