interface Config {
  url: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
}

const config: Config = {
  url: process.env.REACT_APP_API_URL || '',
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
};

export default config;
