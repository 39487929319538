import { put, call, takeEvery, getContext } from 'redux-saga/effects';
import { Action, ActionMeta } from 'redux-actions';
import { normalize } from 'normalizr';
// models
import { UsersList } from 'models/User';
// actions
import {
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFail,
  getListUsers,
  getListUsersFail,
  getListUsersSuccess,
  getUsersOverviewInfo,
  getUsersOverviewInfoFail,
  getUsersOverviewInfoSuccess,
  getUserHealthIndexFail,
  getUserHealthIndex,
  getUserHealthIndexSuccess,
  getUserMoodIndexSuccess,
  getUserMoodIndexFail,
  getUserMoodIndex,
  getUserComfortIndexSuccess,
  getUserComfortIndexFail,
  getUserComfortIndex,
} from 'modules/user/actions';
// types
import {
  Api,
  IPieChartOverviewOptions,
  ILineChartOverviewOptions,
} from '@healthplate/types';

function* onGetListUsers({ payload }: Action<Api.User.IGetUsersListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.users.list, payload);

    const normalizedPayload = normalize(result.users, UsersList);

    yield put(
      getListUsersSuccess(normalizedPayload, {
        start: result.start,
        limit: result.limit,
        length: result.length,
        total: result.total,
      }),
    );
  } catch (e) {
    yield put(getListUsersFail(e));
  }
}

function* onGetCurrentUser({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.users.retrieve, payload, {
      include: ['health', 'mood', 'comfort'],
    });

    yield put(getCurrentUserSuccess(result));
  } catch (e) {
    yield put(getCurrentUserFail(e));
  }
}

function* onGetUsersOverviewInfo({
  payload,
}: Action<IPieChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.users.overview, payload);

    yield put(getUsersOverviewInfoSuccess(result));
  } catch (e) {
    yield put(getUsersOverviewInfoFail(e));
  }
}

function* onGetUserHealthIndex({
  payload,
  meta,
}: ActionMeta<string, ILineChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.users.getUserHealthPointOverview,
      payload,
      meta,
    );

    yield put(getUserHealthIndexSuccess(response.datasets.data));
  } catch (e) {
    yield put(getUserHealthIndexFail(e));
  }
}

function* onGetUserMoodIndex({
  payload,
  meta,
}: ActionMeta<string, ILineChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.users.getUserMoodPointOverview,
      payload,
      meta,
    );

    yield put(getUserMoodIndexSuccess(response.datasets.data));
  } catch (e) {
    yield put(getUserMoodIndexFail(e));
  }
}

function* onGetUserComfortIndex({
  payload,
  meta,
}: ActionMeta<string, ILineChartOverviewOptions>) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.users.getUserComfortPointOverview,
      payload,
      meta,
    );

    yield put(getUserComfortIndexSuccess(response.datasets.data));
  } catch (e) {
    yield put(getUserComfortIndexFail(e));
  }
}

export default function* initUserSagas() {
  yield takeEvery(getCurrentUser, onGetCurrentUser);
  yield takeEvery(getListUsers, onGetListUsers);
  yield takeEvery(getUsersOverviewInfo, onGetUsersOverviewInfo);
  yield takeEvery(getUserHealthIndex, onGetUserHealthIndex);
  yield takeEvery(getUserMoodIndex, onGetUserMoodIndex);
  yield takeEvery(getUserComfortIndex, onGetUserComfortIndex);
}
