import {
  all,
  put,
  call,
  select,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Action } from 'redux-actions';
// schemas
import { ListTags } from 'models/Tag';
// selectors
import { selectTableOptions } from 'modules/tag/selectors';
// actions
import {
  getTags,
  getTagsSuccess,
  getTagsFail,
  removeTags,
  removeTagsSuccess,
  removeTagsFail,
  getTagsBySearch,
  getTagsBySearchSuccess,
  getTagsBySearchFail,
} from 'modules/tag/actions';
import { addAlert } from 'modules/core/actions';
// types
import { Api } from '@healthplate/types';

function* onGetTags({ payload }: Action<Api.Tag.IGetTagListOptions>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.tags.list, payload);

    const normalizedList = normalize(result.data, ListTags);

    yield put(
      getTagsSuccess(normalizedList, {
        nextUrl: result.nextUrl,
        prevUrl: result.prevUrl,
        totalCount: result.totalCount,
      }),
    );
  } catch (e) {
    yield put(getTagsFail(e));
  }
}

function* onRemoveTags({ payload }: Action<string[]>) {
  try {
    const api = yield getContext('api');
    const options = yield select(selectTableOptions);

    yield all(payload.map((tagId) => call(api.tags.delete, tagId)));
    yield all([
      put(removeTagsSuccess(payload)),
      put(
        addAlert({
          type: 'success',
          message: `Tags successfully removed`,
        }),
      ),
      put(getTags(options)),
    ]);
  } catch (e) {
    yield put(removeTagsFail(e));
  }
}

function* onSearchTags({ payload }: Action<string>) {
  try {
    const api = yield getContext('api');
    const result = yield call(api.tags.list, { q: payload });

    const normalizedPayload = normalize(result.data, ListTags);

    yield put(
      getTagsBySearchSuccess(normalizedPayload, {
        hasMore: result.hasMore,
      }),
    );
  } catch (e) {
    yield put(getTagsBySearchFail(e));
  }
}

export default function* initTagSagas() {
  yield takeEvery(getTags, onGetTags);
  yield takeEvery(removeTags, onRemoveTags);
  yield takeEvery(getTagsBySearch, onSearchTags);
}
